var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    {
      staticClass: "form-row",
      class: { readonly: _vm.readOnly },
      attrs: { gutter: 0 }
    },
    [
      _vm._l(_vm.list, function(item, idx) {
        return [
          (item.showFn
          ? item.showFn(_vm.propPrefix)
          : true)
            ? _c(
                "el-col",
                {
                  key: item.kname + "" + idx,
                  class: { border: _vm.border },
                  attrs: { span: item.span || 24 }
                },
                [
                  _vm._t(
                    item.kname,
                    [
                      item.type === "list"
                        ? _vm._l(_vm.form[item.kname], function(m, idx) {
                            return _c("c-form-row", {
                              key: idx,
                              staticClass: "inner",
                              attrs: {
                                readOnly: _vm.readOnly,
                                list: item.models,
                                border: item.border,
                                propPrefix: item.kname + "." + idx + "."
                              },
                              model: {
                                value: _vm.form[item.kname][idx],
                                callback: function($$v) {
                                  _vm.$set(_vm.form[item.kname], idx, $$v)
                                },
                                expression: "form[item.kname][idx]"
                              }
                            })
                          })
                        : _c(
                            "el-form-item",
                            _vm._b(
                              {
                                staticClass: "inner",
                                attrs: {
                                  rules:
                                    item.itemProps &&
                                    item.itemProps.rules &&
                                    (typeof item.itemProps.rules === "function"
                                      ? item.itemProps.rules(_vm.form)
                                      : item.itemProps.rules),
                                  prop:
                                    (_vm.propPrefix || "") + (item.kname || "")
                                }
                              },
                              "el-form-item",
                              item.itemProps,
                              false
                            ),
                            [
                              _c("v-form-item", {
                                attrs: {
                                  propPrefix:
                                    (_vm.propPrefix || "") + (item.kname || ""),
                                  component: item.component,
                                  valueFormat: item.valueFormat,
                                  valueFilter: item.valueFilter,
                                  readOnly: _vm.readOnly,
                                  readOnlyFn: item.readOnlyFn
                                },
                                model: {
                                  value: _vm.form[item.kname],
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, item.kname, $$v)
                                  },
                                  expression: "form[item.kname]"
                                }
                              })
                            ],
                            1
                          )
                    ],
                    { data: item }
                  )
                ],
                2
              )
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }