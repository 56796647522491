import "core-js/modules/es.array.concat";import "core-js/modules/es.array.for-each";import "core-js/modules/es.array.includes";import "core-js/modules/es.array.some";import "core-js/modules/es.object.to-string";import "core-js/modules/es.string.includes";import "core-js/modules/web.dom-collections.for-each";import _objectSpread from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/objectSpread2";import constantRoutes from '@/router/constantRoutes';
import asyncRoutes from '@/router/asyncRoutes';
import { checkRole } from '@/settings';
/**
                                         * Use meta.role to determine if the current user has permission
                                         * @param roles
                                         * @param route
                                         */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {return route.meta.roles.includes(role);});
  }
  return true;
}

/**
   * Filter asynchronous routing tables by recursionz
   * @param routes asyncRoutes
   * @param roles
   */
export function filterAsyncRoutes(routes, roles) {
  var res = [];

  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);

    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });

  return res;
}

var state = {
  routes: [],
  addRoutes: [] };


var mutations = {
  // eslint-disable-next-line no-shadow
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  } };


var actions = {
  generateRoutes: function generateRoutes(_ref, roles) {var commit = _ref.commit;
    return new Promise(function (resolve) {
      var accessedRoutes;
      // if (roles.includes('admin')) {
      //   accessedRoutes = asyncRoutes || []
      // } else {
      /* 这里控制路由加载 */

      if (checkRole) {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
      } else {
        accessedRoutes = asyncRoutes;
      }
      // }
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  } };


export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions };