/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-10-13 09:13:03
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-15 16:31:46
 */
/* 展馆管理 */
import { load } from '../routeloader';
import EmptyRoute from '@/layout/EmptyRoute';
export default {
  path: '/doctor',
  component: load('layout/index'),
  redirect: 'noRedirect',
  name: 'layout',
  // 不管子菜单有几个都显示
  alwaysShow: true,
  meta: {
    title: '医生管理',
    icon: 'iconfont icon-gongzuotai'
    // roles: [126]
  },
  children: [
  {
    path: 'approval',
    component: load('views/doctor/approval/index'),
    name: 'doctor-approval',
    meta: {
      title: '医生审核'
      // img: require('@/assets/icons/1.png'),
      // affix: true
      // matchedKey: 'layout'
      // noCache: true
      // roles: [131]
    } },

  {
    path: 'manage',
    component: load('views/doctor/manage/index'),
    name: 'doctor-manage',
    meta: {
      title: '医生列表'
      // img: require('@/assets/icons/1.png'),
      // affix: true
      // matchedKey: 'layout'
      // noCache: true
      // roles: [131]
    } }] };