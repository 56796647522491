var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editTag" },
    [
      _vm.inputVisible
        ? _c("el-input", {
            ref: "saveTagInput",
            staticClass: "w100",
            attrs: { size: "small" },
            on: { blur: _vm.handleInputConfirm },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleInputConfirm($event)
              }
            },
            model: {
              value: _vm.form,
              callback: function($$v) {
                _vm.form = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "form"
            }
          })
        : _vm.value
        ? _c(
            "el-tag",
            {
              staticClass: "pointer",
              attrs: {
                closable: _vm.closeable,
                "disable-transitions": true,
                type: _vm.tagType
              },
              on: { click: _vm.showInput, close: _vm.handleClose }
            },
            [_vm._v(_vm._s(_vm.form))]
          )
        : _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.showInput } },
            [_vm._v(_vm._s(_vm.defaultVal))]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }