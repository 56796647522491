/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-10-13 09:13:03
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-16 09:43:05
 */
/* sys 系统管理 */
import { load } from '../routeloader';

import EmptyRoute from '@/layout/EmptyRoute';

export default {
  path: '/sys',
  component: load('layout/index'),
  redirect: 'noRedirect',
  name: 'layout',
  // 不管子菜单有几个都显示
  alwaysShow: true,
  meta: {
    title: '系统管理'
    // img: require('@/assets/icons/1.png')
    // roles: [1]
  },
  children: [
  {
    path: 'role',
    component: load('views/sys/role/index'),
    name: 'sys-role',
    meta: {
      title: '角色管理'
      // img: require('@/assets/icons/1.png'),
      // affix: true
      // matchedKey: 'layout'
      // noCache: true
      // roles: [5]
    } },

  // {
  //   path: 'department',
  //   component: load('views/sys/department/index'),
  //   name: 'sys-department',
  //   meta: {
  //     title: '组织机构管理',
  //     // img: require('@/assets/icons/1.png'),
  //     // affix: true
  //     // matchedKey: 'layout'
  //     // noCache: true
  //     roles: [59]
  //   }
  // },
  {
    path: 'user',
    component: load('views/sys/user/index'),
    name: 'sys-user',
    meta: {
      title: '用户管理'
      // img: require('@/assets/icons/1.png'),
      // affix: true
      // matchedKey: 'layout'
      // noCache: true
      // roles: [4]
    } },

  {
    path: 'log',
    component: load('views/sys/log/index'),
    name: 'sys-log',
    meta: {
      title: '操作日志'
      // noCache: true
      // roles: [14],
    } },

  {
    path: 'advertising',
    component: load('views/sys/advertising/index'),
    name: 'sys-advertising',
    meta: {
      title: '广告管理'
      // noCache: true
      // roles: [14],
    } }] };