/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-09-03 18:35:12
 * @LastEditors: silencc
 * @LastEditTime: 2020-09-22 18:14:23
 */
import Api from './lib/api';
import drawer from './components/c-drawer';

/* 全局扩展配置 */
export default (function (Vue) {
  // 过滤器
  Vue.use(require('./filters')["default"]);
  // mixin
  Vue.mixin(require('./mixins/vCommon')["default"]);
  // 指令
  Vue.use(require('./directives')["default"]);
  // 共用组件
  Vue.use(require('./widget')["default"]);
  // api
  Vue.use(Api);
  // drawer
  Vue.prototype.$drawer = drawer;
});