import "core-js/modules/es.regexp.exec";import "core-js/modules/es.string.replace"; /*
                                                                                    背景：开发中遇到的表单输入，往往会有对输入内容的限制，比如不能输入表情和特殊字符，只能输入数字或字母等。
                                                                                    我们常规方法是在每一个表单的 on-change 事件上做处理。
                                                                                    <template>
                                                                                      <input type="text" v-model="note" @change="vaidateEmoji" />
                                                                                    </template>
                                                                                    
                                                                                    <script>
                                                                                      export default {
                                                                                        methods: {
                                                                                          vaidateEmoji() {
                                                                                            var reg = /[^\u4E00-\u9FA5|\d|\a-zA-Z|\r\n\s,.?!，。？！…—&$=()-+/*{}[\]]|\s/g
                                                                                            this.note = this.note.replace(reg, '')
                                                                                          },
                                                                                        },
                                                                                      }
                                                                                    </script>
                                                                                    复制代码
                                                                                    这样代码量比较大而且不好维护，所以我们需要自定义一个指令来解决这问题。
                                                                                    需求：根据正则表达式，设计自定义处理表单输入规则的指令，下面以禁止输入表情和特殊字符为例。
                                                                                    
                                                                                    
                                                                                    使用：将需要校验的输入框加上 v-emoji 即可
                                                                                    
                                                                                    <template>
                                                                                      <input type="text" v-model="note" v-emoji />
                                                                                    </template>
                                                                                    
                                                                                    */


var findEle = function findEle(parent, type) {
  return parent.tagName.toLowerCase() === type ? parent : parent.querySelector(type);
};

var trigger = function trigger(el, type) {
  var e = document.createEvent('HTMLEvents');

  e.initEvent(type, true, true);
  el.dispatchEvent(e);
};

var emoji = {
  bind: function bind(el, binding, vnode) {
    // 正则规则可根据需求自定义
    var regRule = /[^\u4E00-\u9FA5|\d|\a-zA-Z|\r\n\s,.?!，。？！…—&$=()-+/*{}[\]]|\s/g;
    var $inp = findEle(el, 'input');

    el.$inp = $inp;
    $inp.handle = function () {
      var val = $inp.value;

      $inp.value = val.replace(regRule, '');

      trigger($inp, 'input');
    };
    $inp.addEventListener('keyup', $inp.handle);
  },
  unbind: function unbind(el) {
    el.$inp.removeEventListener('keyup', el.$inp.handle);
  } };


export default emoji;