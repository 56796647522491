var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function() {
                  return [_vm._t("header")]
                },
                proxy: true
              },
              {
                key: "footer",
                fn: function() {
                  return [_vm._t("footer")]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        "el-dialog",
        _vm.attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }