import "core-js/modules/es.array.filter";import "core-js/modules/es.array.find-index";import "core-js/modules/es.array.for-each";import "core-js/modules/es.array.includes";import "core-js/modules/es.array.map";import "core-js/modules/es.array.some";import "core-js/modules/es.array.splice";import "core-js/modules/es.function.name";import "core-js/modules/es.object.to-string";import "core-js/modules/es.string.includes";import "core-js/modules/web.dom-collections.for-each";import _objectSpread from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/objectSpread2"; /* eslint-disable no-shadow */
var state = {
  isRefresh: false, //是否是刷新的
  cached: {},
  visitedViews: [] };


var mutations = {};

function filterView(view) {
  if (!view) {
    return view;
  }var

  fullPath =






  view.fullPath,name = view.name,path = view.path,meta = view.meta,params = view.params,query = view.query,matched = view.matched;

  return {
    fullPath: fullPath,
    name: name,
    path: path,
    meta: meta,
    params: params,
    query: query,
    matched: matched ? matched.map(function (i) {return {
        meta: i.meta,
        name: i.name,
        path: i.path };}) :
    undefined };

}
var actions = {
  retsetState: function retsetState(_ref) {var state = _ref.state;
    state.visitedViews = [];
    state.cached = {};
  },

  setMatched: function setMatched(_ref2) {var dispatch = _ref2.dispatch,state = _ref2.state;
    var obj = {};

    state.visitedViews.forEach(function (view) {
      if (view.meta.affix && view.meta.matchedKey) {

        var arr = obj[view.meta.matchedKey] || [];

        if (!arr.includes(view.name)) {
          arr.push(view.name);
        }
        obj[view.meta.matchedKey] = arr;

      } else if (view.matched && !view.meta.noCache) {var
        matched = view.matched;
        var len = matched.length;

        if (len < 2) {
          return;
        }


        for (var idx = 0; idx < matched.length; idx++) {
          var key = matched[idx].name;

          if (idx < len - 1) {
            var vnext = matched[idx + 1];var
            meta = vnext.meta,name = vnext.name;

            if (meta && (meta.affix || !meta.noCache)) {
              var _arr = obj[key] || [];

              if (!_arr.includes(name)) {
                _arr.push(name);
              }
              obj[key] = _arr;
            }
          }
        }
      }
    });
    state.cached = obj;
  },


  addView: function addView(_ref3, view) {var dispatch = _ref3.dispatch,state = _ref3.state;
    try {
      if (state.visitedViews.some(function (v) {return v.path === view.path;}) && state.isRefresh === false) {
        return;
      }
      state.isRefresh = false;
      view = filterView(view);

      var idx = state.visitedViews.findIndex(function (v) {return v.name === view.name;});

      if (idx > -1) {
        state.visitedViews.splice(idx, 1, _objectSpread(_objectSpread({}, view), {}, { title: view.meta.title || '' }));
      } else {
        state.visitedViews.push(_objectSpread(_objectSpread({},
        view), {}, { title: view.meta.title || '' }));

      }

      dispatch('setMatched');
    } catch (error) {
      console.log('addView', error);
    }
  },

  delView: function delView(_ref4, view) {var dispatch = _ref4.dispatch,state = _ref4.state;
    return new Promise(function (resolve) {
      var idx = state.visitedViews.findIndex(function (i) {return i.path === view.path;});

      if (idx > -1) {
        state.visitedViews.splice(idx, 1);
      }
      dispatch('setMatched');
      resolve({ visitedViews: state.visitedViews });
    });
  },

  refreshView: function refreshView(_ref5, view) {var dispatch = _ref5.dispatch,state = _ref5.state;
    return new Promise(function (resolve) {var
      name = view.name;

      var key = 'layout';

      if (view.matched) {
        var len = view.matched.length;

        key = view.matched[len - 2].name;
      }
      state.cached[key] = state.cached[key].filter(function (i) {return i !== name;});
      state.isRefresh = true;
      resolve();
    });
  },

  delOthersViews: function delOthersViews(_ref6, view) {var dispatch = _ref6.dispatch,state = _ref6.state;
    return new Promise(function (resolve) {
      var arr = state.visitedViews.filter(function (i) {return i.meta.affix;});

      if (view && !view.meta.affix) {
        arr.push(view);
      }
      state.visitedViews = arr;
      dispatch('setMatched');
      resolve({ visitedViews: arr });
    });
  } };


export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions };