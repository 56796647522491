import _objectSpread from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/objectSpread2"; /*
                                                                                                                                                 * @Description:
                                                                                                                                                 * @Version: 1.0
                                                                                                                                                 * @Autor: silencc
                                                                                                                                                 * @Date: 2020-09-11 11:53:50
                                                                                                                                                 * @LastEditors: silencc
                                                                                                                                                 * @LastEditTime: 2020-10-17 17:36:21
                                                                                                                                                 */
import {
request,
postForm,
postFile } from
'./request/axios';
import {
RestfulApi } from
'./request/help';

var _apis = {};

export var model = function model(key) {return _apis[key] || new RestfulApi(key);};

export var getblob = function getblob(url, options, type) {return request(url, _objectSpread(_objectSpread({},
  options), {}, {
    responseType: 'blob' }),
  type);};


export default (function (Vue) {
  Vue.prototype.$Api = {
    http: request,
    model: model,
    getblob: getblob,
    postForm: postForm,
    postFile: postFile };

});
/*
      import Api from "@/plugins/lib/api";
      Vue.use(Api)
    
      使用：
        const [err, res] = await this.$Api.model("orders").list({
          params:{...},
          data: {...}
          headers: {...}
        });
        const [err, res] = await this.$Api.http("orders").get('xxx',{
          params:{...},
        });
    
    
        const [err, res] = await this.$Api.http("orders",{
          params:{...},
          data: {...},
          headers: {...},
        },'post');
    
    
        const [err, res] = await this.$Api.postForm("orders",{
          params:{...},
          data: {...},
          headers: {...},
        });
    
        const [err, res] = await this.$Api.postFile("orders",{
          params:{...},
          data: {...},
          headers: {...},
        });
    
        const [err, res] = await this.$Api.getblob("orders",{
          params:{...},
          data: {...},
          headers: {...},
        }，type);
    
    */