import "core-js/modules/es.array.for-each";import "core-js/modules/es.function.name";import "core-js/modules/es.object.to-string";import "core-js/modules/web.dom-collections.for-each";import "core-js/modules/web.dom-collections.iterator"; // 全局组件 遍历加载目录下子文件夹的.vue文件  有 name 才会被注册
export default (function (Vue) {
  var files = require.context('./', true, /\.vue/);
  files.
  keys().
  forEach(function (key) {
    var comp = files(key)["default"];
    if (comp.name) {
      Vue.component(comp.name, comp);
    }
  });
});