var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "downloadFile",
    _vm._b({}, "downloadFile", _vm.$attrs, false),
    [
      _c("el-button", { attrs: { size: "small", type: "primary" } }, [
        _vm._v(" 导出 ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }