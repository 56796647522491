import "core-js/modules/es.date.to-string";import "core-js/modules/web.timers"; /* eslint-disable no-invalid-this */
/* eslint-disable consistent-this */

export var requestAnimFrame = function () {
  return window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  function (callback) {
    window.setTimeout(callback, 6000 / 60);
  };
}();
// raf 节流函数
export function rafDebounce(fn) {// touchmove  scroll节流 高阶函数
  return function () {
    var ctx = this;
    var args = arguments;

    requestAnimFrame(function () {
      // eslint-disable-next-line no-unused-expressions
      fn && fn.apply(ctx, args);
    });
  };
}

/**
    防抖函数 demo
    事件停止 wait 毫秒后执行 func
    mustRun>0 表示启用 代表 mustRun 毫秒内，必然会触发一次 handler
   * @param {Function} func 执行方法
   * @param {Number} wait 事件停止 wait 毫秒后执行 func
   * @param {Number} mustRun mustRun>0 表示 mustRun 毫秒内必然执行一次 mustRun<=0 表示不启用
  */
export function throttle(func, wait, mustRun) {
  var timeout = null;

  var startTime = new Date();

  return function () {
    var context = this;

    var args = arguments;
    var curTime = new Date();

    clearTimeout(timeout);
    // 如果达到了规定的触发时间间隔，触发 handler
    if (mustRun > 0 && curTime - startTime >= mustRun) {
      func.apply(context, args);
      startTime = curTime;
      if (!timeout) {
        context = args = null;
      }
      // 没达到触发间隔，重新设定定时器
    } else {
      timeout = setTimeout(function () {
        if (!timeout) {
          context = args = null;
        }
        return func.apply(context, args);
      }, wait);
    }
  };
}