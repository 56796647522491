import "core-js/modules/es.array.is-array";import "core-js/modules/es.object.keys";import _typeof from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/typeof"; /**
                                                                                                                                                                                                                       * 克隆
                                                                                                                                                                                                                       */
export function deepClone(obj) {
  if (_typeof(obj) !== 'object') {
    return obj || undefined;
  }
  var result = Array.isArray(obj) ? [] : {};

  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (_typeof(obj[key]) === 'object') {
        result[key] = deepClone(obj[key]); // 递归复制
      } else {
        result[key] = obj[key];
      }
    }
  }
  return result;
}

// 21. `size`：获取不同类型变量的长度
// 这个的实现非常巧妙，利用Blob类文件对象的特性，获取对象的长度。
// 多重三元运算符
export var size = function size(val) {return Array.isArray(val) ?
  val.length :
  val && _typeof(val) === 'object' ?
  val.size || val.length || Object.keys(val).length :
  typeof val === 'string' ?
  new Blob([val]).size :
  0;};
// size([1, 2, 3, 4, 5]); // 5
// size('size'); // 4
// size({ one: 1, two: 2, three: 3 }); // 3