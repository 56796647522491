import Vue from 'vue';
import Drawer from './c-drawer';
import store from '@/store';
import router from '@/router';

var vm = new Vue({
  store: store,
  router: router,
  render: function render(createElement) {
    return createElement(Drawer);
  } }).
$mount();

export default vm.$children[0];