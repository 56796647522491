/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-10-13 09:13:03
 * @LastEditors: silencc
 * @LastEditTime: 2020-10-15 14:47:05
 */

// import store from '@/store'

// export const hasPermission = permissionRoles => {
//   const roles = store.getters && store.getters.roles
//   if (permissionRoles && permissionRoles instanceof Array && permissionRoles.length > 0) {
//     return roles.some(role => permissionRoles.includes(role))
//   }
//   return false
// }

import { hasPermission } from '../../lib/modules/permission';

var permission = {
  inserted: function inserted(el, binding) {var
    value = binding.value;

    if (!hasPermission(value)) {
      el.parentNode && el.parentNode.removeChild(el);
    }

    // if (value && value instanceof Array && value.length > 0) {
    //   const permissionRoles = value

    //   const hasPermission = roles.some(role => {
    //     return permissionRoles.includes(role)
    //   })

    //   if (!hasPermission) {
    //     el.parentNode && el.parentNode.removeChild(el)
    //   }
    // } else {
    //   // throw new Error(`need roles! Like v-permission="['admin','editor']"`)
    // }
  } };


var install = function install(Vue) {
  Vue.directive('permission', permission);
};

if (window.Vue) {
  window.permission = permission;
  Vue.use(install); // eslint-disable-line
}

permission.install = install;

export default permission;