import _objectSpread from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/objectSpread2";import './waves.scss';

export default (function (Vue) {
  Vue.directive('waves', {
    bind: function bind(el, binding) {
      el.addEventListener('click', function (e) {
        var customOpts = _objectSpread({}, binding.value);
        var opts = _objectSpread({
          ele: el, // 波纹作用元素
          type: 'hit' },
        customOpts);

        var target = opts.ele;

        if (target) {
          target.style.position = 'relative';
          target.style.overflow = 'hidden';
          var rect = target.getBoundingClientRect();
          var ripple = target.querySelector('.waves-ripple');

          if (!ripple) {
            ripple = document.createElement('span');
            ripple.className = 'waves-ripple';
            // eslint-disable-next-line no-multi-assign
            ripple.style.height = ripple.style.width = Math.max(rect.width, rect.height) + 'px';
            target.appendChild(ripple);
          } else {
            ripple.className = 'waves-ripple';
          }
          switch (opts.type) {
            case 'center':
              ripple.style.top = rect.height / 2 - ripple.offsetHeight / 2 + 'px';
              ripple.style.left = rect.width / 2 - ripple.offsetWidth / 2 + 'px';
              break;
            default:
              ripple.style.top = e.pageY - rect.top - ripple.offsetHeight / 2 - document.body.scrollTop + 'px';
              ripple.style.left = e.pageX - rect.left - ripple.offsetWidth / 2 - document.body.scrollLeft + 'px';}

          ripple.className = 'waves-ripple z-active';
          return false;
        }
      }, false);
    } });

});