/* eslint-disable no-underscore-dangle */
/* 通用 */
import { load } from '../routeloader';

export default [
{
  path: '/',
  redirect: '/home/staff' },

// {
//   // 首页配置
//   path: '/home',
//   component: load('views/constant/home/index'),
//   hidden: true,
//   noCache: true
// },
{
  path: '/lock',
  name: 'lock',
  component: load('views/constant/lock/index'),
  hidden: true,
  meta: {
    title: '锁屏',
    noCache: true } }];