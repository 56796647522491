var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-radio-group",
    {
      attrs: { size: _vm.size },
      on: { change: _vm.change },
      model: {
        value: _vm.form,
        callback: function($$v) {
          _vm.form = $$v
        },
        expression: "form"
      }
    },
    _vm._l(_vm.options, function(item) {
      return _c(
        "el-radio-button",
        { key: item[_vm.valueKey], attrs: { label: item[_vm.valueKey] } },
        [
          _vm._v(" " + _vm._s(item[_vm.labelKey]) + " "),
          item.count > 0
            ? _c(
                "c-box",
                {
                  staticStyle: {
                    position: "absolute",
                    left: "80%",
                    top: "0",
                    "z-index": "999"
                  },
                  attrs: {
                    radius: "50%",
                    bcolor: "#FF6161",
                    padding: "2px 5px",
                    color: "#FFF"
                  }
                },
                [_vm._v(_vm._s(item.count))]
              )
            : _vm._e()
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }