var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-switch", {
    attrs: {
      value: _vm.value,
      "inactive-value": _vm.statusOptions[0].value,
      "active-value": _vm.statusOptions[1].value,
      disabled:
        typeof _vm.disabled === "function"
          ? _vm.disabled(_vm.row)
          : _vm.disabled
    },
    on: { change: _vm.change }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }