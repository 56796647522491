import Vue from 'vue';
import Router from 'vue-router';

/**
                                  * Note: sub-menu only appear when route children.length >= 1
                                  * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
                                  *
                                  * hidden: true                   if set true, item will not show in the sidebar(default is false)
                                  * alwaysShow: true               if set true, will always show the root menu
                                  *                                if not set alwaysShow, when item has more than one children route,
                                  *                                it will becomes nested mode, otherwise not show the root menu
                                  * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
                                  * name:'router-name'             the name is used by <keep-alive> (must set!!!)
                                  * meta : {
                                     roles: ['admin','editor']    control the page roles (you can set multiple roles)
                                     title: 'title'               the name show in sidebar and breadcrumb (recommend set)
                                     noCache: true                if set true, the page will no be cached(default is false)
                                                                 缓存页面--
                                                                   注意： 必须页面组件 name 和路由 name必须一样 才能生效
                                     affix: true                  if set true, the tag will affix in the tags-view  标签一直存在
                                     icon: 'svg-name'             the icon show in the sidebar
                                     breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
                                                                   面包屑导航
                                     activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
                                     parent: true                 设置是否只显示父级 tagsView
                                     activeName: 'xxx'            高亮父级的路由 name
                                   }
                                  */

/**
                                      * constantRoutes
                                      * a base page that does not have permission requirements
                                      * all roles can be accessed
                                      */
import constantRoutes from './constantRoutes';

// hooks
import routerHook from './routerHook';

Vue.use(Router);

// 去掉router push  报错
var originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  // if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location)["catch"](function (err) {return err;});
};

var createRouter = function createRouter() {return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {return { y: 0 };},
    routes: constantRoutes });};


var router = createRouter();

routerHook(router);

export default router;

// reset router
export function resetRouter() {
  var newRouter = createRouter();

  router.matcher = newRouter.matcher;
  router.options.routes = constantRoutes;
  router.hasAdd = false;
}