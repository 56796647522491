//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import downloadFile from '../../components/downloadFile/index';
export default {
  name: 'exportBtn',
  components: { downloadFile: downloadFile } };