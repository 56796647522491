/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 10:17:16
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-15 01:58:13
 */
var getters = {
  sidebar: function sidebar(state) {return state.app.sidebar;},
  device: function device(state) {return state.app.device;},
  roles: function roles(state) {return state.user.roles;},
  permission_routes: function permission_routes(state) {return state.permission.routes;},
  visitedViews: function visitedViews(state) {return state.tagsView.visitedViews;},
  cached: function cached(state) {return state.tagsView.cached;} };


export default getters;