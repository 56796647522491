var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        _vm._b(
          {
            ref: "upload",
            staticClass: "upload-image",
            class: {
              "hidden-upload":
                _vm.fileList.length >= _vm.limit || _vm.isUploading
            },
            attrs: {
              action: "",
              "auto-upload": false,
              "on-change": _vm.handleChange,
              "before-remove": _vm.beforeRemove,
              "before-upload": _vm.beforeUpload,
              "file-list": _vm.fileList,
              headers: _vm.headers,
              limit: _vm.limit,
              multiple: false,
              "on-exceed": _vm.handleExceed,
              "on-preview": _vm.handlePictureCardPreview,
              "on-remove": _vm.handleRemove,
              "list-type": "picture-card"
            },
            on: {
              "update:fileList": function($event) {
                _vm.fileList = $event
              },
              "update:file-list": function($event) {
                _vm.fileList = $event
              }
            }
          },
          "el-upload",
          _vm.$attrs,
          false
        ),
        [
          _c("i", { staticClass: "el-icon-plus" }),
          _vm.accept
            ? _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  staticStyle: { margin: "10px 0" },
                  attrs: { slot: "tip" },
                  slot: "tip"
                },
                [_vm._v(" " + _vm._s(_vm.desc) + " ")]
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "img-dialog",
          attrs: {
            visible: _vm.dialogVisible,
            "show-close": false,
            "append-to-body": "",
            width: "500px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [_c("img", { attrs: { src: _vm.dialogImageUrl, width: "100%" } })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }