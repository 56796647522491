import './loading.css';

export default (function (Vue) {
  Vue.directive('loading', {
    bind: function bind(el, binding) {
      var tempDiv = document.createElement('div');

      tempDiv.className = 'custom-loading';
      var round = document.createElement('div');

      round.className = 'custom-loading-round';
      tempDiv.appendChild(round);
      el.loadingElement = tempDiv;
      var curStyle = window.getComputedStyle(el);var
      position = curStyle.position;

      if (position === 'absolute' || position === 'relative') {
        el.style.position = position;
      } else {
        el.style.position = 'relative';
      }
      if (binding.value) {
        el.appendChild(tempDiv);
      }
    },
    update: function update(el, binding) {
      if (binding.value) {
        if (el.loadingElement.parentNode === null) {
          el.appendChild(el.loadingElement);
        }
      } else {
        if (el === el.loadingElement.parentNode) {
          el.removeChild(el.loadingElement);
        }
      }
    },
    unbind: function unbind(el) {
      if (el.loadingElement.parentNode === el) {
        el.removeChild(el.loadingElement);
      }
      el.loadingElement = null;
    } });

});