//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'c-grid-table',
  props: {
    options: {
      type: Array,
      "default": function _default() {return [];}
      // {
      //   label: '审核目的',
      //   key: 'aa',
      //   span: 50,
      //   height: '200px',
      //   labelWidth: '200px',
      //   padding: '',
      //   filterFn: v => v,
      //   component: {
      //     name: '',
      //     props: {},
      //     events: {}
      //   }
      // }
    },
    data: {
      type: Object,
      "default": function _default() {return {};} },

    placeholder: {
      type: String,
      "default": '' } }


  // data() {
  //   return {
  //     dd: {},
  //     s: [
  //       { label: '审核目的', key: 'aa' },
  //       { label: '审核范围', key: 'aa' },
  //       { label: '审核依据', key: 'aa' },
  //       { label: '参加人员', key: 'aa' },
  //       { label: '审核日期', key: 'aa', span: 50 },
  //       { label: '编制', key: 'aa', span: 50 },
  //       { label: '管理评审综述', key: 'aa' },
  //       { label: '管评输出', key: 'aa' },
  //       { label: '附 各部门工作报告', key: 'aa' },
  //       { label: '质量负责人', key: 'aa' },
  //       { label: '主任', key: 'aa' }
  //     ]
  //   }
  // },
  // watch: {},
  // computed: {},
  // methods: {},
  // created() {},
  // mounted() {}
};