import "core-js/modules/es.array.find"; /*
                                         * @Description: 配置项
                                         */

export var advertLink = [{
  label: '请选择',
  value: 0 },

{
  label: '项目',
  value: 1 },

{
  label: '文章',
  value: 2 },

{
  label: '视频',
  value: 3 },

{
  label: '外链',
  value: 4 }];



export var labeledStatus = [{
  label: '正常',
  value: 0 },

{
  label: '异常',
  value: 1 }];



export var goodsStatus = [{
  label: '待发货',
  value: 0 },

{
  label: '已发货',
  value: 1 },

{
  label: '已完成',
  value: 2 },

{
  label: '已取消',
  value: 3 }];


export var LaboratoryCode = [{
  label: '德谷实验室',
  value: 'DG' },

{
  label: '其他实验室',
  value: '' }];




export var apymentType = [{
  label: '线上',
  value: 0 },

{
  label: '线下',
  value: 1 }];



//filterFn(v) {
//return ['支付宝', '微信', '招商'][v - 1]
// }
//export const payCatalog = ['支付宝', '微信', '招商']

export var laboratoryPayCatalog = [{
  label: '招商银行',
  value: 1 },

{
  label: '建设银行',
  value: 2 }];



export var payCatalog = [{
  label: '支付宝',
  value: 1 },

{
  label: '微信',
  value: 2 },

{
  label: '招商',
  value: 3 }];


export var payBack = [{
  label: '个人回款',
  value: 0 },
{
  label: '协作组回款',
  value: 1 }];


export var lookStatus = [{
  label: '医生',
  value: 0 },

{
  label: '销售',
  value: 1 },

{
  label: '物流',
  value: 2 }];


export var invoiceType = [{
  label: '个人发票',
  value: '1' },

{
  label: '企业发票',
  value: '2' }];


export var invoiceStatus = [{
  label: '开票中',
  value: '1' },

{
  label: '开票失败',
  value: '2' },

{
  label: '开票成功',
  value: '3' }];


export var timeStatus = [{
  label: '最近7天',
  value: 0 },

{
  label: '本月',
  value: 1 },

{
  label: '今年',
  value: 2 }];


export var upAndDownStatus = [{
  label: '上架',
  value: 0 },

{
  label: '下架',
  value: 1 }];


export var defultStatus = [{
  label: '默认',
  value: 0 },

{
  label: '不默认',
  value: 1 }];


export var doctorUnreviewedStatus = [{
  label: '待审核',
  value: 0 },

{
  label: '不通过',
  value: 2 }];


//0-待提交/1-已提交/2-已驳回/3-检测中
export var orderExamineStatus = [
{
  label: '待提交',
  value: 0 },

{
  label: '已提交',
  value: 1 },

{
  label: '已驳回',
  value: 2 },

{
  label: '检测中',
  value: 3 }];



export var orderListStatus = [{
  label: '检测中',
  value: 0 },

{
  label: '驳回',
  value: 2 }];


export var integralAuditStatus = [{
  label: '待审核',
  value: 4 }];

export var integralAuditStatus1 = [{
  label: '待审核',
  value: 4 },
{
  label: '已通过',
  value: 3 }];

export var auditStatus = [{
  label: '待审核',
  value: 0 },

{
  label: '已通过',
  value: 1 },

{
  label: '不通过',
  value: 2 }];



export var peopleType = [{
  label: '创建者',
  value: 0 },

{
  label: '管理员',
  value: 1 },

{
  label: '普通成员',
  value: 2 }];


export var exOrderStatus = [{
  label: '退项',
  value: 1 },

{
  label: '改项',
  value: 2 },

{
  label: '取消',
  value: 3 }];



export var status = [{
  label: '禁用',
  value: 1 },

{
  label: '启用',
  value: 0 }];


export var isOrNo = [{
  label: '是',
  value: 1 },

{
  label: '否',
  value: 0 }];



export var isRead = [{
  label: '已读',
  value: 1 },

{
  label: '未读',
  value: 0 }];



export var visible = [{
  label: '不能',
  value: 1 },

{
  label: '能',
  value: 0 }];



export var sex = [{
  label: '男',
  value: 1 },

{
  label: '女',
  value: 2 },

{
  label: '保密',
  value: 3 }];


export var sex2 = [{
  label: '男',
  value: 1 },

{
  label: '女',
  value: 2 }];



export var advertDefault = [{
  label: '默认',
  value: 1 }];


export var refundStatus = [{
  label: '待退款',
  value: 0 },

{
  label: '已退款',
  value: 1 }];



export var returnedtatus = [{
  label: '待回款',
  value: 0 },

{
  label: '已回款',
  value: 1 }];



export var costType = [{
  label: '退款',
  value: 1 },

{
  label: '收款',
  value: 2 }];




export var formStatus = [{
  label: '待提交',
  value: 0 },

{
  label: '待审批',
  value: 1 },

{
  label: '审批中',
  value: 2 },

{
  label: '已审批',
  value: 3 }];


export var auditReasonNum = [{
  label: '',
  value: 0 },

{
  label: '兑换错了',
  value: 1 },

{
  label: '发货太慢',
  value: 2 },

{
  label: '不想要了',
  value: 3 },

{
  label: '其他原因',
  value: 4 }];



// (1-博士研究生/2-硕士研究生/3-双学士学位/4-本科/5-大学专科/6-大专及以下
export var eduLevel = [{
  label: '博士研究生',
  value: 1 },

{
  label: '硕士研究生',
  value: 2 },

{
  label: '双学士学位',
  value: 3 },

{
  label: '本科',
  value: 4 },

{
  label: '大学专科',
  value: 5 },

{
  label: '大专及以下',
  value: 6 }];


// (1-1/2-2/3-3/4-4/5-5/6-6
export var payLevel = [{
  label: '1',
  value: 1 },

{
  label: '2',
  value: 2 },

{
  label: '3',
  value: 3 },

{
  label: '4',
  value: 4 },

{
  label: '5',
  value: 5 },

{
  label: '6',
  value: 6 }];



export var accessLevel = [{
  label: 'A',
  value: 'A' },

{
  label: 'B',
  value: 'B' },

{
  label: 'C',
  value: 'C' },

{
  label: 'D',
  value: 'D' }];



export var accessResult = [{
  label: 'A',
  value: 1 },

{
  label: 'B',
  value: 2 },

{
  label: 'C',
  value: 3 }];


export var checkStatus = [{
  label: '待核定',
  value: 1 },

{
  label: '已核定',
  value: 2 }];



export var bonusPointStatus = [{
  label: '待评分',
  value: 0 },

{
  label: '已评分',
  value: 2 }];



export var accessStatus = [{
  label: '未提交',
  value: 0 },

{
  label: '待评定',
  value: 1 },

{
  label: '评定中',
  value: 2 },

{
  label: '已评定',
  value: 3 }];


export var scienceStatus = [{
  label: '已保存',
  value: 0 },

{
  label: '待评估',
  value: 1 },

{
  label: '执行中',
  value: 2 },

{
  label: '已评估',
  value: 3 },

{
  label: '已结束',
  value: 4 },

{
  label: '已撤销',
  value: 5 },

{
  label: '已驳回',
  value: 6 }

// {
//   label: '待完成',
//   value: 7
// },
// {
//   label: '已完成',
//   value: 8
// }
];
export var scienceNeedStatus = [{
  label: '待评估',
  value: 1 },

{
  label: '执行中',
  value: 2 },

{
  label: '已评估',
  value: 3 },

{
  label: '已结束',
  value: 4 },

{
  label: '已撤销',
  value: 5 },

{
  label: '已驳回',
  value: 6 }

// {
//   label: '待完成',
//   value: 7
// },
// {
//   label: '已完成',
//   value: 8
// }
];
export var accessStatus1 = [{
  label: '待评定',
  value: 0 },

{
  label: '已评定',
  value: 1 },

{
  label: '评定中',
  value: 2 }];



export var evaluateStatus = [{
  label: '未提交',
  value: 0 },

{
  label: '待评定',
  value: 1 },

{
  label: '已评定',
  value: 2 }];



export var accessStatus2 = [{
  label: '未评定',
  value: 0 },

{
  label: '已评定',
  value: 1 }];



export var orderType = [{
  label: '普通订单',
  value: 0 },

{
  label: '科研订单',
  value: 1 },

{
  label: '协作订单',
  value: 2 }];



export var jobTitle = [{
  label: '医生',
  value: 0 },

{
  label: '主任',
  value: 1 }];



export var orderStatus = [{
  label: '待接单',
  value: 0 },

{
  label: '待付款',
  value: 1 },

{
  label: '检测中',
  value: 2 },

{
  label: '已完成',
  value: 3 },

{
  label: '已解读',
  value: 4 },

{
  label: '已取消',
  value: 5 }];



export var orderStatus2 = [{
  label: '待付款',
  value: 1 },

{
  label: '检测中',
  value: 2 },

{
  label: '已完成',
  value: 3 },

{
  label: '已解读',
  value: 4 },

{
  label: '已取消',
  value: 5 }];


export var taskStatus = [{
  label: '已审批',
  value: 0 },

{
  label: '未审批',
  value: 1 }];



export var quarterStatus = [{
  label: '第一季度',
  value: 1 },

{
  label: '第二季度',
  value: 2 },

{
  label: '第三季度',
  value: 3 },

{
  label: '第四季度',
  value: 4 }];



export var quarterMonth = [
[{
  label: '一月',
  value: 0 },

{
  label: '二月',
  value: 1 },

{
  label: '三月',
  value: 2 }],


[{
  label: '四月',
  value: 2 },

{
  label: '五月',
  value: 0 },

{
  label: '六月',
  value: 1 }],


[{
  label: '七月',
  value: 2 },

{
  label: '八月',
  value: 2 },

{
  label: '九月',
  value: 2 }],


[{
  label: '十月',
  value: 2 },

{
  label: '十一月',
  value: 2 },

{
  label: '十二月',
  value: 2 }]];




export var month = [{
  label: '一月',
  value: 0 },

{
  label: '二月',
  value: 1 },

{
  label: '三月',
  value: 2 },

{
  label: '四月',
  value: 2 },

{
  label: '五月',
  value: 0 },

{
  label: '六月',
  value: 1 },

{
  label: '七月',
  value: 2 },

{
  label: '八月',
  value: 2 },

{
  label: '九月',
  value: 2 },

{
  label: '十月',
  value: 2 },

{
  label: '十一月',
  value: 2 },

{
  label: '十二月',
  value: 2 }];



export var transport = [{
  label: '干冰',
  value: 0 },

{
  label: '冰袋',
  value: 1 },

{
  label: '常温',
  value: 2 },

{
  label: '其他',
  value: 3 }];



var types = {};

export default types;

export var statusText = ['禁用', '启用'];

export var page = {
  total: 0,
  page: 1,
  pageSize: 10,
  orderItems: [] };


export var getType = function getType(name, v) {var _types$name;
  var ret = (_types$name = types[name]) === null || _types$name === void 0 ? void 0 : _types$name.find(function (i) {return String(i.value) === String(v);});

  return ret ? ret.label : '';
};

export var getLabel = function getLabel(list, v) {
  var ret = list.find(function (i) {return i.value === v;});

  return ret ? ret.label : '';
};

export var getQuarter = function getQuarter(year, quarter) {
  return year + '年' + getLabel(quarterStatus, quarter);
};

export var deliveryType = [
{
  label: '组织',
  value: 0 },

{
  label: '核酸',
  value: 1 },

{
  label: '文库',
  value: 2 }];



//核酸样本————核酸洗脱液
export var nucleicAcid = [
{
  label: 'NaCl',
  value: 0 },

{
  label: 'Tris',
  value: 1 },

{
  label: 'EDTA',
  value: 2 },
{
  label: 'SDS',
  value: 3 },

{
  label: 'Triton X-100',
  value: 4 },

{
  label: 'NaBrO₃',
  value: 5 },

{
  label: '异硫氰酸胍',
  value: 6 }];



//核酸样本——核酸类型
export var nucleicAcidType = [
{
  label: 'DNA',
  value: 0 },
{
  label: 'RNA',
  value: 1 }];



//文库样本————是否平衡文库
export var isBalance = [
{
  label: '是',
  value: 0 },
{
  label: '否',
  value: 1 }];



//文库样本————是否平衡文库
export var isCircular = [
{
  label: '是',
  value: 0 },
{
  label: '否',
  value: 1 }];



//文库样本————测序平台
export var sequencingPlatform = [
{
  label: 'Illumina',
  value: 0 },
{
  label: 'PacBio',
  value: 1 }];



//文库样本————测序策略
export var sequencingStrategy = [
{
  label: 'Illumina',
  value: 0 }];



//文库样本————数据量单位
export var sequencingDataUnit = [
{
  label: 'G',
  value: 0 },
{
  label: 'M',
  value: 1 },
{
  label: 'bp',
  value: 2 },
{
  label: 'Cluster',
  value: 3 }];



//子文库————文库类型
export var libraryType = [
{
  label: 'G',
  value: 0 },
{
  label: 'M',
  value: 1 },
{
  label: 'bp',
  value: 2 }];



//子文库————index类型
export var indexTypeOption = [
{
  label: 'G',
  value: 0 }];



//子文库————建库试剂盒
export var kit = [
{
  label: 'G',
  value: 0 }];