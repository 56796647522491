import { render, staticRenderFns } from "./AddDialog.vue?vue&type=template&id=5f4e97ee&"
import script from "./AddDialog.vue?vue&type=script&lang=js&"
export * from "./AddDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f4e97ee')) {
      api.createRecord('5f4e97ee', component.options)
    } else {
      api.reload('5f4e97ee', component.options)
    }
    module.hot.accept("./AddDialog.vue?vue&type=template&id=5f4e97ee&", function () {
      api.rerender('5f4e97ee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/plugins/widget/c-form/demo/components/AddDialog.vue"
export default component.exports