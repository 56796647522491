//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'c-date-picker',
  props: {
    valueFormat: {
      type: String,
      "default": 'yyyy-MM-dd HH:mm:ss' },

    format: {
      type: String,
      "default": 'yyyy-MM-dd' } } };