//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'c-title',
  props: {
    // type: {
    //   type: String,
    //   default: '1'
    // }
  },
  methods: {} };