var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-pagination" },
    [
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.page.page,
          "page-size": _vm.page.pageSize,
          "page-sizes": [10, 20, 50],
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.page.total
        },
        on: {
          "update:currentPage": function($event) {
            return _vm.$set(_vm.page, "page", $event)
          },
          "update:current-page": function($event) {
            return _vm.$set(_vm.page, "page", $event)
          },
          "update:pageSize": function($event) {
            return _vm.$set(_vm.page, "pageSize", $event)
          },
          "update:page-size": function($event) {
            return _vm.$set(_vm.page, "pageSize", $event)
          },
          "current-change": _vm.search,
          "size-change": _vm.search
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }