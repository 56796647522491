/* 动态加载 路由 */
import constant from './modules/constant';
import home from './modules/home';
import project from './modules/project';
import doctor from './modules/doctor';
import entry from './modules/entry';
import order from './modules/order';

import sys from './modules/sys';

// 模块的顺序与菜单栏的顺序一致
export default [
entry, // 录入下单
order, // 订单模块 （案例）
// meQuarter,
// 404 page must be placed at the end !!!
{
  path: '*',
  // redirect: '/home',
  redirect: '/entry/staff',
  hidden: true,
  meta: {
    noCache: true } }];