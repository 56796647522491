import "core-js/modules/es.number.constructor"; //
//
//
//

export default {
  name: 'my-date',
  props: {
    time: {
      type: String | Number,
      "default": '' } } };