//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'c-col-item',
  props: {
    label: {
      type: String,
      "default": '' },

    labelWidth: {
      type: String },

    labelAlign: {
      type: String },

    labelBcolor: {
      type: String },

    border: {
      type: String,
      "default": '' },

    align: {
      type: String,
      "default": 'center' },

    padding: {
      type: String },

    height: {
      type: String,
      "default": '100%' } },


  data: function data() {
    return {
      style: {},
      labelW: '',
      paddingStyle: '' };

  },
  computed: {
    borderStyle: function borderStyle() {
      var parent = this.$parent;

      if (parent && parent.$options.componentName === 'c-col') {
        return { right: parent.borderStyle };
      }

      return this.border ? { right: this.border } : {};
    } },

  methods: {
    setStyle: function setStyle() {
      var parent = this.$parent;

      if (parent && parent.$options.componentName === 'c-col') {
        this.labelW = this.labelWidth || parent.labelWidth;
        this.paddingStyle = this.padding || parent.padding;
      } else {
        this.style = { all: this.border };
        this.labelW = this.labelWidth || parent.labelWidth;
        this.paddingStyle = this.padding || parent.padding;
      }
    } },

  created: function created() {
    this.setStyle();
  } };