//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import vModelMixins from '@/plugins/mixins/vModelMixins';
import vFormItem from './formItem.vue';

export default {
  name: 'c-form-row',
  mixins: [vModelMixins],
  components: { vFormItem: vFormItem },
  props: {
    // 前缀
    propPrefix: {
      type: String,
      "default": '' },

    // 数据模型
    list: {
      type: Array,
      "default": function _default() {return [];} },

    // 是否只读
    readOnly: {
      type: Boolean },

    // 是否只读
    border: {
      type: Boolean,
      "default": false } } };