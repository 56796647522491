//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'c-container',
  props: {
    padding: {
      type: String,
      "default": '15px' } } };