import "core-js/modules/es.date.now";import "core-js/modules/es.date.to-string"; /*
                                                                                   cookie 请使用 js-cookie
                                                                                 */
export var sStorage = {
  // 存储
  setItem: function setItem(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  },
  // 取出数据
  getItem: function getItem(key) {return JSON.parse(sessionStorage.getItem(key));},
  // 删除数据
  removeItem: function removeItem(key) {
    sessionStorage.removeItem(key);
  },
  // 清空数据
  clear: function clear() {
    sessionStorage.clear();
  } };

export var lStorage = {
  // 本地存数据，days 有效时间（天）
  setItem: function setItem(key, value, days) {
    var Days = days || 30; // 有效时间默认30天
    var exp = new Date();
    var expires = exp.getTime() + Days * 24 * 60 * 60 * 1000;

    localStorage.setItem(
    key,
    JSON.stringify({
      value: value,
      expires: expires }));


  },
  getItem: function getItem(key) {
    var o = JSON.parse(localStorage.getItem(key));

    if (!o || o.expires < Date.now()) {
      return null;
    }
    return o.value;
  },
  removeItem: function removeItem(key) {
    localStorage.removeItem(key);
  },
  // 清空数据
  clear: function clear() {
    localStorage.clear();
  } };