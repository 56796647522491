/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-10-13 09:13:03
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-12-29 13:31:17
 */
/* 展馆管理 */
import { load } from '../routeloader';
import EmptyRoute from '@/layout/EmptyRoute';
export default {
  path: '/entry',
  component: load('layout/index'),
  redirect: 'noRedirect',
  name: 'layout',
  // 不管子菜单有几个都显示
  alwaysShow: true,
  meta: {
    title: '录入下单',
    icon: 'iconfont icon-gongzuotai'
    // roles: [126]
  },
  children: [
  {
    path: 'staff',
    component: load('views/order/entry/index'),
    name: 'entry-order',
    meta: {
      title: '录入下单' } },


  {
    path: 'sample',
    component: load('views/order/entry/sample') },

  {
    path: 'finish',
    component: load('views/order/entry/finish') }] };