var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "v-dialog"
    },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.visible,
            width: _vm.width
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            close: _vm.onClose
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                size: _vm.size,
                "label-width": _vm.labelWidth
              }
            },
            [
              _c("c-form-row", {
                attrs: { list: _vm.models, readOnly: _vm.readOnly },
                model: {
                  value: _vm.form,
                  callback: function($$v) {
                    _vm.form = $$v
                  },
                  expression: "form"
                }
              })
            ],
            1
          ),
          _c(
            "c-box",
            {
              attrs: { flex: "row", justify: "space-between", padding: "10px" }
            },
            [_c("h3", [_vm._v("箱子流水")])]
          ),
          _c("c-table", {
            attrs: {
              columns: _vm.columns,
              data: _vm.list,
              border: "",
              size: "mini",
              stripe: "",
              height: "500px"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }