
import ElementUI, {
Loading,
MessageBox,
Message,
Notification } from
'element-ui';
import './element-ui.scss';
export default (function (Vue) {
  Vue.use(ElementUI);

  Vue.prototype.$loading = Loading.service;
  Vue.prototype.$msgbox = MessageBox;
  Vue.prototype.$alert = MessageBox.alert;
  Vue.prototype.$confirm = MessageBox.confirm;
  Vue.prototype.$prompt = MessageBox.prompt;
  Vue.prototype.$notify = Notification;
  Vue.prototype.$message = Message;
});