import _objectSpread from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/objectSpread2";import defaultSettings from '@/settings';


var state = _objectSpread({},
defaultSettings);


var mutations = {
  // eslint-disable-next-line no-shadow
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {var key = _ref.key,value = _ref.value;
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value;
    }
  } };


var actions = {
  changeSetting: function changeSetting(_ref2, data) {var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
  } };


export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions };