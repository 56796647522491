var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-date-picker",
    _vm._g(
      _vm._b(
        {
          staticStyle: { width: "220px" },
          attrs: {
            size: "small",
            valueFormat: _vm.valueFormat,
            format: _vm.format,
            placeholder: "请选择日期"
          }
        },
        "el-date-picker",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }