import "core-js/modules/es.object.freeze"; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { detailOption } from './option';

export default {
  props: {},
  data: function data() {
    return {
      loading: false,
      detailOption: Object.freeze(detailOption(this)),
      info: {} };

  },
  watch: {},
  computed: {},
  methods: {},


  created: function created() {
    // this.getData()
  },
  mounted: function mounted() {} };