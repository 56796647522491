import "core-js/modules/es.string.trim"; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import vModelMixins from '@/plugins/mixins/vModelMixins';

export default {
  // name: 'v-form-item',
  mixins: [vModelMixins],
  props: {
    // prop前缀
    propPrefix: {
      type: String,
      "default": '' },

    // 组件属性
    component: {
      type: Object,
      reuqired: true,
      "default": function _default() {return {};} },

    // 输出值格式化
    valueFormat: {
      type: Function,
      "default": undefined },

    // 输入值格式化
    valueFilter: {
      type: Function,
      "default": undefined },

    // 是否只读
    readOnly: {
      type: Boolean,
      "default": false },

    // 是否只读条件
    readOnlyFn: {
      type: Function,
      "default": undefined } },


  data: function data() {
    return {};
  },
  computed: {
    innerValue: function innerValue() {
      var ret = this.form;

      if (this.valueFilter) {
        ret = this.valueFilter(this.form);
      }
      return ret;
    } },

  methods: {
    onInput: function onInput(e) {
      if (typeof e === 'string') {var _this$component$props;
        if (((_this$component$props = this.component.props) === null || _this$component$props === void 0 ? void 0 : _this$component$props.type) === 'textarea') {
        } else {
          e = e.trim();
        }
      }
      //   return v
      if (this.valueFormat) {
        e = this.valueFormat(e);
      }
      this.$emit('input', e);
    },
    events: function events(type) {
      var events = this.component.events || {};

      events[type] && events[type](this.propPrefix);
    } } };