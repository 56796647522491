import { DockingOSS } from './modules/aliOss';

var AliOssCOnf = {
  region: 'oss-cn-hangzhou',
  accessKeyId: '',
  accessKeySecret: '',
  bucket: '',
  path: '',
  secure: true };


export var AliOssClient = new DockingOSS('v1/js/aliyun-oss-sdk.min.js', AliOssCOnf);