/* 不需要登录的路由 */
import { load } from './routeloader';

export default [
{
  path: '/redirect',
  component: load('layout/index'),
  hidden: true,
  children: [
  {
    path: '/redirect/:path*',
    component: load('views/constant/redirect/index') }] },



{
  path: '/login',
  component: load('views/constant/login/index'),
  hidden: true },

{
  path: '/printOrder',
  component: load('views/constant/printOrder/index'),
  hidden: true },


{
  path: '/404',
  component: load('views/constant/error-page/404'),
  hidden: true },

// {
//   path: '/401',
//   component: load('views/constant/error-page/401'),
//   hidden: true
// },
{
  path: '/error',
  component: load('layout/index'),
  hidden: true,
  redirect: 'noRedirect',
  name: 'ErrorPages',
  meta: {
    title: 'Error Pages',
    icon: '404' },

  children: [
  // {
  //   path: '401',
  //   component: load('views/constant/error-page/401'),
  //   name: 'Page401',
  //   meta: { title: '401', noCache: true }
  // },
  {
    path: '404',
    component: load('views/constant/error-page/404'),
    name: 'Page404',
    meta: { title: '404', noCache: true } }] }];