//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import vModelMixins from '@/plugins/mixins/vModelMixins';

export default {
  name: 'c-search',
  mixins: [vModelMixins],
  props: {
    option: {
      type: Array,
      "default": function _default() {return [];} } },


  data: function data() {
    return {};
  },
  methods: {
    submit: function submit() {
      this.$emit('submit');
    } } };