//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cStyleMixin from '../../mixins/cStyleMixin';
export default {
  name: 'c-box',
  mixins: [cStyleMixin],
  props: {
    hover: {
      type: Boolean,
      "default": false } } };