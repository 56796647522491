import "core-js/modules/es.number.constructor";import _objectSpread from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/objectSpread2";import _defineProperty from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/defineProperty"; //
//
//
//
//
//
//
//

var getStyle = function getStyle(key, val, fn) {return val ? _defineProperty({}, key, fn ? fn(val) : val) : null;};

export default {
  name: 'c-row',

  componentName: 'c-row',

  props: {
    tag: {
      type: String,
      "default": 'div' },

    gutter: Number,
    border: {
      type: Boolean,
      "default": false },

    labelAlign: {
      type: String },

    labelBcolor: {
      type: String },

    height: {
      type: String,
      "default": undefined // l
    },
    color: {
      type: String,
      "default": undefined },

    bcolor: {
      type: String,
      "default": undefined },

    borderStyle: {
      type: String,
      "default": '1px solid #ddd' },

    radius: {
      type: String,
      "default": '0' },

    labelWidth: {
      type: String,
      "default": '100px' },

    padding: {
      type: String,
      "default": '12px 10px' } },


  computed: {
    style: function style() {
      var ret = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
        display: 'flex',
        flexWrap: 'wrap',
        position: 'relative',
        zIndex: 1,
        flexGrow: 1,
        flexShrink: 0 },
      getStyle('height', this.height)),
      getStyle('font-size', this.size)),
      getStyle('border-radius', this.radius)),

      getStyle('color', this.color)),
      getStyle('background', this.bcolor));


      if (this.gutter) {
        // ret.marginLeft = `-${this.gutter / 2}px`
        // ret.marginRight = ret.marginLeft
      }
      if (this.border) {
        ret.borderLeft = this.borderStyle;
        ret.borderTop = this.borderStyle;
      }

      return ret;
    } },


  render: function render(h, ctx) {
    return h(
    this.tag,
    {
      style: this.style },

    this.$slots["default"]);

  } };

//  demo：
// <c-row :border="true">
//   <c-col :span="30">
//     <div>12312312</div>
//     <div>12312312</div>
//     123432
//   </c-col>
//   <c-col :span="70">
//     <c-col-item label="111">123123</c-col-item>
//   </c-col>
// </c-row>