import "core-js/modules/es.array.is-array";import "core-js/modules/es.array.map";import "core-js/modules/es.regexp.exec";import "core-js/modules/es.string.split"; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import vModelMixins from '../../mixins/vModelMixins';
import { AliOssClient } from '../../lib/oss';

export default {
  mixins: [vModelMixins],
  name: 'c-image',
  props: {
    // src: {
    //   type: [String, Array],
    //   default: ''
    // },
    separator: {
      type: String,
      "default": ';' },

    radius: {
      type: Boolean,
      "default": false } },


  data: function data() {
    return {
      visible: false,
      showSrc: '',
      ossUrl: process.env.VUE_APP_API_OSS_CONFIG_PRO_URL };

  },
  computed: {
    list: function list() {
      var imgs = [];

      var src = this.form;

      if (Array.isArray(this.form)) {
        var url = "https://" + AliOssClient.allOSS.options.bucket + "." + AliOssClient.allOSS.options.endpoint.hostname + '/' + AliOssClient.allOSS.options.bucket;

        this.form.map(function (item, index) {
          imgs.push(url + '/' + item);
        });
        src = imgs;
      }
      var ret;

      if (!src) {
        ret = [];
      }
      if (typeof src === 'string') {
        ret = src.split(this.separator);
      }
      if (Array.isArray(src)) {
        ret = src;
      }
      return ret;
    } },

  methods: {
    review: function review(src) {
      this.showSrc = src;
      this.visible = true;
    } } };