//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'c-pagination',
  props: {
    page: {
      type: Object,
      required: true },

    search: {
      type: Function,
      required: true } },


  watch: {
    page: function page(val) {
      this.$emit('update:page', val);
    } } };