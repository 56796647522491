import "core-js/modules/es.object.freeze";import _objectSpread from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/objectSpread2";import { page, status } from '@/utils/options';

export default {
  data: function data() {
    return {
      loading: false,
      status: Object.freeze(status),
      page: _objectSpread({},
      page),

      query: {},
      selectionList: [],
      list: [] };

  },
  methods: {
    handleReset: function handleReset() {
      this.query = {};
      this.getData();
    },
    handleSelection: function handleSelection(selection) {
      this.selectionList = selection;
    } } };