import "core-js/modules/es.number.constructor";import "core-js/modules/es.regexp.constructor";import "core-js/modules/es.regexp.exec";import "core-js/modules/es.regexp.to-string"; /* eslint-disable indent */
var rules = {
  money: {
    reg: /^(([1-9]\d*)|0)(?:\.\d{1,2})?$/,
    errMsg: '金额格式错误' },

  "int": {
    reg: /^(0|[1-9][0-9]*)$/,
    errMsg: '请输入整数' },

  phone: {
    reg: /^1[3-9][0-9]{9}$/,
    errMsg: '手机号格式错误' },

  age: {
    reg: /^(?:[1-9][0-9]?|1[01][0-9]|120)$/,
    errMsg: '请输入1～120之间正整数' },

  tel: {
    reg: /^(0\d{2,3}-\d{7,8})(-\d{1,4})?$/,
    errMsg: '电话号码格式错误' },

  chinese: {
    reg: /^[\u4E00-\u9FA5\uF900-\uFA2D]*$/,
    errMsg: '请输入中文' },

  pwd: {
    reg: /^[a-zA-Z0-9]{12,20}$/,
    errMsg: '请输入12-20位字母或数字' },

  idCard: {
    reg: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
    errMsg: '身份证格式错误' },

  telephone: {
    reg: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/,
    errMsg: '请输入正确的座机号或者手机号' },

  xxx: {
    reg: /^(?![a-z]+$)(?![0-9]+$)[a-zA-Z0-9]{6,16}$/,
    errMsg: '请输入6-16位字母加数字' },

  inputLevel: {
    //^.{3}$
    reg: /^.{1,6}$/,
    errMsg: '请输入1-6个字' },

  project: {
    reg: /^(?![0-9]+$)(?![A-Z]+$)[0-9A-Z]{2,20}$/,
    errMsg: '2～20位大写字母加数字组合' },

  pictureNone: {
    reg: /^\s*$/,
    errMsg: '图片不能为空' },

  sampleNumber: {
    reg: /^(?![0-9]+$)(?![A-Z]+$)[0-9A-Z]{0,12}$/,
    errMsg: '最大12位的大写字母和数字' },

  userAccount: {
    reg: /^[a-zA-Z0-9]{0,12}$/,
    errMsg: '用户账号为英文和数字的组合' },

  hospitalCode: {
    reg: /^(?![0-9]+$)(?![A-Z]+$)[0-9A-Z]{6,10}$/,
    errMsg: '6-10位的大写字母加数字' },

  email: {
    reg: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/,
    errMsg: '邮箱格式不正确' } };



// validator 生成辅助函数
export var genVali = function genVali(condition, errMsg, trigger) {return {
    validator: function validator(rule, value, callback) {
      condition(value) ? callback() : callback(new Error(errMsg));
    },
    trigger: trigger };};



// rule 生成器
export var genRules = function genRules(rule, val, message) {var trigger = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'change';
  if (typeof rule === 'function') {
    return genVali(rule, message || '验证失败！', trigger);
  }

  if (rule instanceof RegExp) {
    return genVali(function (str) {return rule.test(str);}, message || message, trigger);
  }

  if (rules[rule]) {var _rules$rule =
    rules[rule],reg = _rules$rule.reg,errMsg = _rules$rule.errMsg;

    if (reg) {
      return genVali(function (str) {return reg.test(str) || str === undefined || str == '';}, message || errMsg, trigger);
    }
  }

  switch (rule) {
    case 'require':
      return { required: true, message: message || '不能为空！', trigger: trigger };
    case 'email':
      return { type: 'email', message: message || '请输入正确的邮箱地址', trigger: trigger };
    case 'number':
      return genVali(function (str) {return !isNaN(str) || str === undefined;}, message || '请输入数字', trigger);
    case 'max':
      return genVali(function (str) {return Number(str) <= Number(val);}, message || "\u4E0D\u80FD\u5927\u4E8E".concat(val), trigger);
    case 'min':
      return genVali(function (str) {return str >= val;}, message || "\u4E0D\u80FD\u5C0F\u4E8E".concat(val), trigger);
    default:
      return {};}

};
// 模型生成器
// eslint-disable-next-line no-useless-call
export var genModels = function genModels(models) {return function (ctx) {return models.call(null, genRules, ctx);};};