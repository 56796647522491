//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import vModelMixins from '../../mixins/vModelMixins';

export default {
  name: 'c-tab',
  mixins: [vModelMixins],
  props: {
    options: {
      type: Array,
      "default": function _default() {return [];} },

    size: {
      "default": 'small' },

    valueKey: {
      "default": 'value' },

    labelKey: {
      "default": 'label' } },


  methods: {
    change: function change() {
      this.$emit('change');
    } } };