import "core-js/modules/es.array.filter";import "core-js/modules/es.date.to-string";import "core-js/modules/es.object.to-string";import "core-js/modules/es.regexp.to-string"; /*
                                                                                                                                                                                * @Description:
                                                                                                                                                                                * @Version: 1.0
                                                                                                                                                                                * @Autor: silencc
                                                                                                                                                                                * @Date: 2020-10-13 09:13:03
                                                                                                                                                                                * @LastEditors: silencc
                                                                                                                                                                                * @LastEditTime: 2020-10-15 16:45:01
                                                                                                                                                                                */
// 全局过滤器
// eslint-disable-next-line no-unused-vars
import { parseTime } from '../lib/modules/date';
import { parseMoney } from '../lib/modules/number';

export default (function (Vue) {
  Vue.filter('upperCase', function (value) {
    if (!value) return '';
    value = value.toString();
    return value.toUpperCase();
  });

  Vue.filter('moneyFilter', function (value) {
    if (!value || isNaN(value)) return value;
    return parseMoney(value);
  });

  Vue.filter('timeFilter', function (value) {
    if (!value) return '';
    return parseTime(value, '{y}-{m}-{d} {h}:{i}:{s}');
  });

  Vue.filter('dateFilter', function (value) {
    if (!value) return '';
    return parseTime(value, '{y}-{m}-{d}');
  });
});