import _objectSpread from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/objectSpread2"; /*
                                                                                                                                                 * @Description:
                                                                                                                                                 * @Version: 1.0
                                                                                                                                                 * @Autor: silencc
                                                                                                                                                 * @Date: 2020-11-13 10:17:16
                                                                                                                                                 * @LastEditors: silencc
                                                                                                                                                 * @LastEditTime: 2020-11-15 02:47:28
                                                                                                                                                 */
import { sStorage } from '@/plugins/lib/modules/webStorage';
import { deepClone } from '@/plugins/lib/modules/object';

var KEY = 'v_ddwax';

export function vuexToSessionStorage(store) {
  var savedState = sStorage.getItem(KEY);

  if (savedState) {
    var newState = _objectSpread(_objectSpread({}, store.state), savedState);
    // const newState = { ...store.state }

    try {
      store.commit('SET_TOKEN', newState.auth.token);
    } catch (e) {
      // console.log(e)
    }
    store.replaceState(newState);
  }
  store.subscribe(function (action, state) {
    var storeClone = deepClone(state);

    sStorage.setItem(KEY, storeClone);
    // Util.sStorage.setItem('vuex', state);
  });
}