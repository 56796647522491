var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "color-blue" }, [
    _c("span", [_vm._v(_vm._s(_vm.$attrs.prefix))]),
    _vm.$attrs.format
      ? _c("span", [_vm._v(_vm._s(_vm._f("moneyFilter")(_vm.$attrs.value)))])
      : _c("span", [_vm._v(_vm._s(_vm.$attrs.value))]),
    _c("span", [_vm._v(_vm._s(_vm.$attrs.suffix))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }