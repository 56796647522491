var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-box",
    {
      staticClass: "c-title",
      attrs: {
        border: { left: "3px solid #006f6b" },
        margin: [0, 0, "15px"],
        padding: ["8px", 0, "2px", "10px"]
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }