import _objectSpread from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/objectSpread2"; //
//
//
//
//
//
//
//
//
//
//
//

export default {
  // name: 'myDialog',
  data: function data() {
    return {
      defaultProps: {
        width: '1000px' } };


  },
  computed: {
    attrs: function attrs() {
      return _objectSpread(_objectSpread({}, this.defaultProps), this.$attrs);
    } } };