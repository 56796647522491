//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BackBtn',
  props: {},
  methods: {
    back: function back() {
      this.$router.back();
    } } };