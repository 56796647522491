// import { parseTime } from '@/plugins/lib/modules/date'
import { status, getType } from '@/utils/options';

import { genModels } from '@/plugins/widget/c-form/rules';

export var models = genModels(function (genRules, ctx) {return [
  {
    span: 8,
    kname: 'name',
    itemProps: {
      label: '编号：' } },


  {
    span: 8,
    kname: 'name',
    itemProps: {
      label: '登记时间：' } },


  {
    span: 8,
    kname: 'name',
    itemProps: {
      label: '账户所属部门：' } },


  {
    span: 8,
    kname: 'name',
    itemProps: {
      label: '操作账户：' } },


  {
    span: 8,
    kname: 'name',
    itemProps: {
      label: '库位编码：' } },


  {
    span: 8,
    kname: 'name',
    itemProps: {
      label: '预存天数：' } },


  {
    span: 8,
    kname: 'name',
    itemProps: {
      label: '箱子所属部门：' } },


  {
    span: 8,
    kname: 'name',
    itemProps: {
      label: '货品来源：' } },


  {
    span: 8,
    kname: 'name',
    itemProps: {
      label: '操作人员：' } }];});



export var columns = function columns(ctx) {return [
  {
    label: '操作时间',
    kname: 'id' },

  {
    label: '操作类型',
    kname: 'id' },

  {
    label: '操作账户',
    kname: 'id' },

  {
    label: '部门',
    kname: 'id' },

  {
    label: '操作人',
    kname: 'id' },

  {
    label: '来源/用途',
    kname: 'id' }];};


export var formData = function formData() {return {};};