var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "check-group" },
    [
      _c(
        "el-checkbox-group",
        _vm._b(
          {
            on: { change: _vm.onChange },
            model: {
              value: _vm.arr,
              callback: function($$v) {
                _vm.arr = $$v
              },
              expression: "arr"
            }
          },
          "el-checkbox-group",
          _vm.$attrs,
          false
        ),
        _vm._l(_vm.options, function(item) {
          return _c(
            "el-checkbox",
            { key: item.value, attrs: { label: item.value } },
            [_vm._v(_vm._s(item.label))]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }