import Vue from 'vue';
import Vuex from 'vuex';
import {
vuexToSessionStorage } from
'./help';


// import ws from '@/plugins/store/ws';

import tagsView from './modules/tagsView';
import app from './modules/app';
import settings from './modules/settings';
import permission from './modules/permission';

import user from './modules/user';
import data from './modules/data';

import getters from './getters';

Vue.use(Vuex);


export default new Vuex.Store({
  modules: {
    app: app,
    settings: settings,
    user: user,
    permission: permission,
    // ws,
    data: data,
    tagsView: tagsView },

  getters: getters,
  plugins: [vuexToSessionStorage] });