var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      ref: "upload",
      staticClass: "uploader",
      attrs: { accept: _vm.accept, type: "file", multiple: _vm.limit > 1 },
      on: { change: _vm.onChange }
    }),
    _c("div", [_vm._t("default", null, { list: _vm.upLoadQuene })], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }