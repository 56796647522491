var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-box",
    { attrs: { flex: "row", height: _vm.height, border: _vm.style } },
    [
      _c(
        "c-box",
        {
          attrs: {
            width: _vm.labelW,
            border: _vm.borderStyle,
            shrink: "0",
            padding: _vm.paddingStyle,
            bcolor: "#f5f5f5",
            talign: "center"
          }
        },
        [_vm._v(" " + _vm._s(_vm.label) + " ")]
      ),
      _c(
        "c-box",
        {
          attrs: {
            grow: "1",
            flex: "row",
            justify: "flex-start",
            align: _vm.align,
            padding: _vm.paddingStyle
          }
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }