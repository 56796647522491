/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-10-13 09:13:03
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-15 16:31:46
 */
/* 展馆管理 */
import { load } from '../routeloader';
import EmptyRoute from '@/layout/EmptyRoute';
export default {
  path: '/order', // 一级路由
  component: load('layout/index'),
  redirect: 'noRedirect',
  name: 'layout',
  // 不管子菜单有几个都显示
  alwaysShow: true,
  meta: {
    title: '订单列表', // 一级目录名称
    icon: 'iconfont icon-gongzuotai' // icon 图片
    // roles: [126]
  },
  children: [
  {
    path: 'list', //子菜单路径
    component: load('views/order/list/index'), // 要跳转的路径
    name: 'order-list', // 模块名称
    meta: {
      title: '订单列表' // 二级目录名称
    } }] };