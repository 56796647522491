import "core-js/modules/es.array.concat";import "core-js/modules/es.array.is-array";import "core-js/modules/es.array.join";import "core-js/modules/es.array.map";import "core-js/modules/es.number.constructor";import "core-js/modules/es.object.keys";import _defineProperty from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/defineProperty";import _objectSpread from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/objectSpread2";export default {
  props: {
    grow: {
      type: String,
      "default": undefined },

    shrink: {
      type: String,
      "default": undefined },

    flex: {
      type: String,
      "default": undefined // row column
    },
    fwrap: {
      type: String,
      "default": 'nowrap' // row column
    },
    justify: {
      type: String,
      "default": undefined // space-between space-around flex-start flex-end center
    },
    align: {
      type: String,
      "default": undefined // flex-start flex-end center stretch
    },
    lineheight: {
      type: String,
      "default": undefined // 1,2,3
    },
    size: {
      type: String,
      "default": undefined // l
    },
    weight: {
      type: String,
      "default": undefined // l
    },
    talign: {
      type: String,
      "default": undefined // left right center
    },
    color: {
      type: String,
      "default": undefined },

    bcolor: {
      type: String,
      "default": undefined },

    radius: {
      type: String,
      "default": undefined },

    width: {
      type: String,
      "default": undefined },

    height: {
      type: String,
      "default": undefined },

    padding: {
      type: [Array, Number, String],
      "default": undefined },

    margin: {
      type: [Array, Number, String],
      "default": undefined },

    border: {
      type: Object,
      "default": function _default() {return {};} } },


  computed: {
    calcStyle: function calcStyle() {
      var o = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({},
      this.getStyle('padding', this.padding, this.getSpace)),
      this.getStyle('margin', this.margin, this.getSpace)),
      this.getBorder()),
      this.getStyle('width', this.width)),
      this.getStyle('height', this.height)),
      this.getStyle('font-size', this.size)),
      this.getStyle('font-weight', this.weight)),
      this.getStyle('line-height', this.lineheight)),
      this.getStyle('border-radius', this.radius)),
      this.getStyle('text-align', this.talign)),
      this.getStyle('-webkit-line-clamp', this.line)),

      this.getStyle('justify-content', this.justify)),
      this.getStyle('align-items', this.align)),
      this.getStyle('flex-direction', this.direction)),
      this.getStyle('flex-grow', this.grow)),
      this.getStyle('flex-shrink', this.shrink)),

      this.getStyle('color', this.color)),
      this.getStyle('background', this.bcolor));


      var style = Object.keys(o).
      map(function (k) {return "".concat(k, ":").concat(o[k]);}).
      join(';');

      if (this.flex === 'row') {
        style += '; display: flex; flex-wrap: ' + this.fwrap;
      }
      if (this.flex === 'column') {
        style += '; display: flex; flex-direction: column; flex-wrap: ' + this.fwrap;
      }
      if (this.style) {
        style = style + ';' + this.style;
      }
      return style;
    } },

  methods: {
    getStyle: function getStyle(key, val, fn) {
      return val ? _defineProperty({}, key, fn ? fn(val) : val) : null;
    },
    getSpace: function getSpace(sp) {
      if (typeof sp === 'number') {
        return sp + 'rpx';
      }
      if (typeof sp === 'string') {
        return sp;
      }
      if (Array.isArray(sp)) {
        return sp.join(' ');
      }
      return '0';
    },
    getBorder: function getBorder() {var _this$border =
      this.border,left = _this$border.left,top = _this$border.top,right = _this$border.right,bottom = _this$border.bottom,all = _this$border.all,vertical = _this$border.vertical,horizontal = _this$border.horizontal,
      borderLeft = left || horizontal || all || 0,
      borderRight = right || horizontal || all || 0,
      borderTop = top || vertical || all || 0,
      borderBottom = bottom || vertical || all || 0,
      obj = {};

      if (borderLeft) {
        obj['border-left'] = borderLeft;
      }
      if (borderRight) {
        obj['border-right'] = borderRight;
      }
      if (borderTop) {
        obj['border-top'] = borderTop;
      }
      if (borderBottom) {
        obj['border-bottom'] = borderBottom;
      }
      return obj;
    } } };