import "core-js/modules/es.array.concat";import "core-js/modules/es.array.from";import "core-js/modules/es.array.slice";import "core-js/modules/es.array.sort";import "core-js/modules/es.date.now";import "core-js/modules/es.date.to-string";import "core-js/modules/es.number.constructor";import "core-js/modules/es.object.to-string";import "core-js/modules/es.parse-int";import "core-js/modules/es.regexp.exec";import "core-js/modules/es.set";import "core-js/modules/es.string.iterator";import "core-js/modules/es.string.replace";import "core-js/modules/web.dom-collections.iterator";import _typeof from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/typeof"; /* eslint-disable no-invalid-this */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable radix */

/**
                            * 格式化时间
                            *
                            * @param  {time}
                            * @param  {cFormat} 格式
                            * @return {String} 字符串
                            *
                            * @example parseTime('2018-1-29', '{y}/{m}/{d} {h}:{i}:{s}') // -> 2018/01/29 00:00:00
                            * @example parseTime('2018-1-29', 'a') // -> 大写？
                            */
export function parseTime(time, cFormat) {
  if (!time || arguments.length === 0) {
    return '';
  }
  if (String(time).length === 10) {
    time = Number(time) * 1000;
  }

  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;

  if (_typeof(time) === 'object') {
    date = time;
  } else {
    date = new Date(time);
  }

  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay() };

  var timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key];

    if (key === 'a') {
      return ['一', '二', '三', '四', '五', '六', '日'][value - 1];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });

  return timeStr;
}

/**
  * @param {number} time
  * @param {string} option
  * @returns {string}
  */
export function formatTime(time, option) {
  if (!time || arguments.length === 0) {
    return null;
  }
  if (String(time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = Number(time);
  }
  var d = new Date(time);
  var now = Date.now();

  var diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  }if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  }if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  }if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  }
  return (
    d.getMonth() +
    1 +
    '月' +
    d.getDate() +
    '日' +
    d.getHours() +
    '时' +
    d.getMinutes() +
    '分');

}
/**
   *
   * @param {String} type
   */
export function getDate(type) {
  var now = new Date();
  var nowDate = {
    s: now.getTime(),
    y: now.getFullYear(),
    m: now.getMonth() + 1,
    w: now.getDay() };

  var start;
  var end;
  var dayTime = 3600 * 1000 * 24; // 一天毫秒数
  // eslint-disable-next-line default-case

  switch (type) {
    case 'lastDay':
      start = end = nowDate.s - dayTime;
      break;
    case 'today':
      start = end = nowDate.s;
      break;
    case 'nextDay':
      start = end = nowDate.s + dayTime;
      break;
    case 'lastWeek':
      start = nowDate.s - dayTime * ((nowDate.w + 6) % 7 + 7);
      end = nowDate.s - dayTime * ((nowDate.w + 6) % 7 + 1);
      break;
    case 'thisWeek':
      start = nowDate.s - dayTime * ((nowDate.w + 6) % 7);
      end = nowDate.s + dayTime * ((7 - nowDate.w) % 7);
      break;
    case 'nextWeek':
      start = nowDate.s + dayTime * ((7 - nowDate.w) % 7 + 1);
      end = nowDate.s + dayTime * ((7 - nowDate.w) % 7 + 7);
      break;
    case 'lastMonth':
      if (nowDate.m === 1) {
        start = new Date("".concat(nowDate.y - 1, "-12-01")).getTime();
        end = new Date("".concat(nowDate.y - 1, "-12-31")).getTime();
      } else {
        start = new Date("".concat(nowDate.y, "-").concat(nowDate.m - 1, "-01")).getTime();
        end = new Date("".concat(nowDate.y, "-").concat(nowDate.m, "-01")).getTime() - dayTime;
      }
      break;
    case 'thisMonth':
      start = new Date("".concat(nowDate.y, "-").concat(nowDate.m, "-01")).getTime();
      if (nowDate.m === 12) {
        end = new Date("".concat(nowDate.y + 1, "-12-31")).getTime();
      } else {
        end = new Date("".concat(nowDate.y, "-").concat(nowDate.m + 1, "-01")).getTime() - dayTime;
      }
      break;
    case 'nextMonth':
      if (nowDate.m === 12) {
        start = new Date("".concat(nowDate.y + 1, "-01-01")).getTime();
        end = new Date("".concat(nowDate.y + 1, "-01-31")).getTime();
      } else {
        start = new Date("".concat(nowDate.y, "-").concat(nowDate.m + 1, "-01")).getTime();
        end = new Date("".concat(nowDate.y, "-").concat(nowDate.m + 2, "-01")).getTime() - dayTime;
      }
      break;
    case 'lastYear':
      start = new Date("".concat(nowDate.y - 1, "-01-01")).getTime();
      end = new Date("".concat(nowDate.y - 1, "-12-31")).getTime();
      break;
    case 'thisYear':
      start = new Date("".concat(nowDate.y, "-01-01")).getTime();
      end = new Date("".concat(nowDate.y, "-12-31")).getTime();
      break;
    case 'nextYear':
      start = new Date("".concat(nowDate.y + 1, "-01-01")).getTime();
      end = new Date("".concat(nowDate.y + 1, "-12-31")).getTime();
      break;
    default:
      break;}

  return [start, end];
}


/**
   * 返回指定长度的月份集合
   *
   * @param  {time} 时间
   * @param  {len} 长度
   * @param  {direction} 方向：  1: 前几个月;  2: 后几个月;  3:前后几个月  默认 3
   * @return {Array} 数组
   *
   * @example   getMonths('2018-1-29', 6, 1)  // ->  ["2018-1", "2017-12", "2017-11", "2017-10", "2017-9", "2017-8", "2017-7"]
   */
export function getMonths(time, len, direction) {
  var mm = new Date(time).getMonth();
  var yy = new Date(time).getFullYear();

  direction = isNaN(direction) ? 3 : direction;
  var index = mm;
  var cutMonth = function cutMonth(index) {
    if (index <= len && index >= -len) {
      return direction === 1 ? formatPre(index).concat(cutMonth(++index)) :
      direction === 2 ? formatNext(index).concat(cutMonth(++index)) : formatCurr(index).concat(cutMonth(++index));
    }
    return [];
  };
  var formatNext = function formatNext(i) {
    var y = Math.floor(i / 12);
    var m = i % 12;

    return [yy + y + '-' + (m + 1)];
  };
  var formatPre = function formatPre(i) {
    var y = Math.ceil(i / 12);
    var m = i % 12;

    m = m === 0 ? 12 : m;
    return [yy - y + '-' + (13 - m)];
  };
  var formatCurr = function formatCurr(i) {
    var y = Math.floor(i / 12);
    var yNext = Math.ceil(i / 12);
    var m = i % 12;
    var mNext = m === 0 ? 12 : m;

    return [yy - yNext + '-' + (13 - mNext), yy + y + '-' + (m + 1)];
  };
  // 数组去重
  var unique = function unique(arr) {
    if (Array.hasOwnProperty('from')) {
      return Array.from(new Set(arr));
    }
    var n = {};
    var r = [];

    for (var i = 0; i < arr.length; i++) {
      if (!n[arr[i]]) {
        n[arr[i]] = true;
        r.push(arr[i]);
      }
    }
    return r;
  };

  return direction !== 3 ? cutMonth(index) : unique(cutMonth(index).sort(function (t1, t2) {return new Date(t1).getTime() - new Date(t2).getTime();}));
}


/**
   * 返回指定长度的天数集合
   *
   * @param  {time} 时间
   * @param  {len} 长度
   * @param  {direction} 方向： 1: 前几天;  2: 后几天;  3:前后几天  默认 3
   * @return {Array} 数组
   *
   * @example date.getDays('2018-1-29', 6) // -> ["2018-1-26", "2018-1-27", "2018-1-28", "2018-1-29", "2018-1-30", "2018-1-31", "2018-2-1"]
   */
export function getDays(time, len, diretion) {
  var tt = new Date(time);
  var getDay = function getDay(day) {
    var t = new Date(time);

    t.setDate(t.getDate() + day);
    var m = t.getMonth() + 1;

    return t.getFullYear() + '-' + m + '-' + t.getDate();
  };
  var arr = [];

  if (diretion === 1) {
    for (var i = 1; i <= len; i++) {
      arr.unshift(getDay(-i));
    }
  } else if (diretion === 2) {
    for (var _i = 1; _i <= len; _i++) {
      arr.push(getDay(_i));
    }
  } else {
    for (var _i2 = 1; _i2 <= len; _i2++) {
      arr.unshift(getDay(-_i2));
    }
    arr.push(tt.getFullYear() + '-' + (tt.getMonth() + 1) + '-' + tt.getDate());
    for (var _i3 = 1; _i3 <= len; _i3++) {
      arr.push(getDay(_i3));
    }
  }
  return diretion === 1 ? arr.concat([tt.getFullYear() + '-' + (tt.getMonth() + 1) + '-' + tt.getDate()]) :
  diretion === 2 ? [tt.getFullYear() + '-' + (tt.getMonth() + 1) + '-' + tt.getDate()].concat(arr) : arr;
}


/**
   * @param  {s} 秒数
   * @return {String} 字符串
   *
   * @example formatHMS(3610) // -> 1h0m10s
   */
export function formatHMS(s) {
  var str = '';

  if (s > 3600) {
    str = Math.floor(s / 3600) + 'h' + Math.floor(s % 3600 / 60) + 'm' + s % 60 + 's';
  } else if (s > 60) {
    str = Math.floor(s / 60) + 'm' + s % 60 + 's';
  } else {
    str = s % 60 + 's';
  }
  return str;
}

/* 获取某月有多少天 */
export function getMonthOfDay(year, month) {
  month = parseInt(month, 10);
  var d = new Date(year, month, 0);

  return d.getDate();
}


/* 获取某年有多少天 */
export function getYearOfDay(time) {
  var firstDayYear = this.getFirstDayOfYear(time);
  var lastDayYear = this.getLastDayOfYear(time);
  var numSecond = (new Date(lastDayYear).getTime() - new Date(firstDayYear).getTime()) / 1000;

  return Math.ceil(numSecond / (24 * 3600));
}

/* 获取某年的第一天 */
export function getFirstDayOfYear(time) {
  var year = new Date(time).getFullYear();

  return year + '-01-01 00:00:00';
}

/* 获取某年最后一天 */
export function getLastDayOfYear(time) {
  var year = new Date(time).getFullYear();

  return year + '-12-31 23:59:59';
}

/* 获取某个日期是当年中的第几天 */
export function getDayOfYear(time) {
  var firstDayYear = this.getFirstDayOfYear(time);
  var numSecond = (new Date(time).getTime() - new Date(firstDayYear).getTime()) / 1000;

  return Math.ceil(numSecond / (24 * 3600));
}

/* 获取某个日期在这一年的第几周 */
export function getDayOfYearWeek(time) {
  var numdays = this.getDayOfYear(time);

  return Math.ceil(numdays / 7);
}

/* 是否之前的时间（包含今天） */
export function isBeforeNow(time) {
  return time.getTime() < Date.now() - 8.64e7;
}

/* 是否之前的时间 */
export function isBeforeNowI(time) {
  return time.getTime() < Date.now();
}
/* 是否之后的时间(包含今天) */
export function isAfterNow(time) {
  return time.getTime() > Date.now() - 8.64e7;
}
/* 是否之后的时间 */
export function isAfterNowI(time) {
  return time.getTime() > Date.now();
}

// 1. `dayOfYear`：当前日期天数
export var dayOfYear = function dayOfYear(date) {return Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);};
// dayOfYear(new Date()); // 285

// 3. `Get Time From Date`：返回当前24小时制时间的字符串
export var getColonTimeFromDate = function getColonTimeFromDate(date) {return date.toTimeString().slice(0, 8);};
// getColonTimeFromDate(new Date()); // "08:38:00"