var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.options, function(item, key) {
      return _c(
        "el-radio",
        {
          key: key,
          attrs: { label: item.value },
          model: {
            value: _vm.form,
            callback: function($$v) {
              _vm.form = $$v
            },
            expression: "form"
          }
        },
        [_vm._v(_vm._s(item.label))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }