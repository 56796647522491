import "core-js/modules/es.array.for-each"; // 全局指令

var directives = [
// 'copy',
'debounce',
// 'draggable',
// 'emoji',
// 'lazyLoad',
// 'longpress',
'permission'
// 'waterMarker',
// 'wave/wave',
// 'loading/loading'
];

export default (function (Vue) {
  directives.forEach(function (i) {
    Vue.directive(i, require("./modules/".concat(i, ".js"))["default"]);
  });

  Vue.directive('focus', {
    inserted: function inserted(el) {
      el.focus();
    } });

});