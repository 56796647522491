import "core-js/modules/es.array.concat";import "core-js/modules/es.array.filter";import "core-js/modules/es.array.join";import "core-js/modules/es.array.last-index-of";import "core-js/modules/es.array.map";import "core-js/modules/es.function.name";import "core-js/modules/es.number.constructor";import "core-js/modules/es.regexp.exec";import "core-js/modules/es.string.replace";import "core-js/modules/es.string.split";import _objectSpread from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/objectSpread2"; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
    					"orderAccessorys":[{
    					"fileFormat": "string",//类型
    					"fileSize": 0,
    					"site": "string",
    					}]
    				*/
import { mapGetters } from "vuex";
import { AliOssClient } from "../../lib/oss";
/* eslint-disable no-unused-vars */

export default {
  name: "upload-image",
  props: {
    value: {
      type: String,
      "default": "" },

    url: {
      type: String,
      "default": "/imgUpload" },

    btnText: {
      type: String,
      "default": "点击上传" },

    maxSize: {
      type: Number,
      "default": 2048 },

    accept: {
      type: String,
      "default": ".png,.jpg,.jpeg" },

    descText: {
      type: String,
      "default": undefined },

    limit: {
      type: Number,
      "default": 3 } },


  data: function data() {
    return {
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      isUploading: false,
      ossURlPro: "https://yjzsapp.oss-cn-hangzhou.aliyuncs.com" };

  },
  /* watch: {
        value: {
          handler(val) {
            console.log('val', val)
           },
          immediate: true,
        },
      },*/

  computed: _objectSpread(_objectSpread({},
  mapGetters({
    token: "user/token" })), {}, {

    action: function action() {
      return process.env.VUE_APP_UPLOAD_URL + this.url;
    },
    // token
    headers: function headers() {
      return {
        Authorization: "Bearer ".concat(this.token) };

    },
    desc: function desc() {
      return (
        this.descText || "\u652F\u6301\u683C\u5F0F\uFF1A".concat(
        this.accept, " \uFF0C\u5355\u4E2A\u6587\u4EF6\u4E0D\u80FD\u8D85\u8FC7").concat(this.maxSize, "Kb\uFF0C\u6700\u591A\u4E0A\u4F20\u4E2A\u6570\uFF1A").concat(this.limit, " \u3002"));

    },
    inputAccept: function inputAccept() {
      return this.accept.replace(".", "image/");
    } }),

  methods: {

    handlePictureCardPreview: function handlePictureCardPreview(file) {
      console.log('handlePictureCardPreview', file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleChange: function handleChange(file, fileLists) {
      //console.log(file);
      //console.log(fileLists);
      // 本地服务器路径
      // TODO 往界面传递上传文件 只能传递string 类型
      this.$emit("input", fileLists);
      this.$emit("change", fileLists);
      // 本地电脑路径
      //console.log(document.getElementsByClassName("el-upload__input")[0].value);
    },
    handleExceed: function handleExceed(files, fileList) {
      this.handleWarning("\u53EA\u80FD\u9009\u62E9 ".concat(this.limit, " \u4E2A\u6587\u4EF6"));
    },
    beforeUpload: function beforeUpload(file) {var
      size = file.size,type = file.type,name = file.name;
      var ret = this.checkFileType(name) && this.checkFileSize(size);
      this.isUploading = ret;
      console.log(ret);
      return ret;
    },
    beforeRemove: function beforeRemove(file, fileList) {
      // return this.$confirm(`确定移除 ${file.name}？`);
    },
    clearFiles: function clearFiles() {
      this.$refs.upload.clearFiles();
    },
    handleRemove: function handleRemove(file, fileList) {
      var ret = fileList.
      map(function (i) {return (
          i.response ?
          i.response.url.split("yjzsapp.oss-cn-hangzhou.aliyuncs.com")[1] :
          i.url.split("yjzsapp.oss-cn-hangzhou.aliyuncs.com")[1]);}).

      join(";");
      this.$emit("change", ret);
      this.$emit("input", ret);
    },
    checkFileType: function checkFileType(name) {
      var t = name.substring(name.lastIndexOf("."));

      if (this.accept.split(",").filter(function (i) {return i === t;}).length === 0) {
        this.handleWarning("\u4E0A\u4F20\u6587\u4EF6\u53EA\u80FD\u662F ".concat(this.accept, " \u683C\u5F0F!"));
        return false;
      }
      return true;
    },
    checkFileSize: function checkFileSize(size) {
      if (size > 1024 * this.maxSize) {
        this.handleWarning("\u4E0A\u4F20\u6587\u4EF6\u5927\u5C0F\u4E0D\u80FD\u8D85\u8FC7 ".concat(this.maxSize, " Kb!"));
        return false;
      }
      return true;
    } } };