import "core-js/modules/es.number.constructor"; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  // name: 'editTag',
  props: {
    closeable: {
      type: Boolean,
      "default": false },

    value: {
      type: [String, Number],
      "default": '' },

    tagType: {
      type: String,
      "default": '' },

    defaultVal: {
      type: String,
      "default": '+ New Tag' } },


  watch: {
    value: {
      handler: function handler(val) {
        this.form = val;
      },
      immediate: true } },


  data: function data() {
    return {
      inputVisible: false,
      form: '' };

  },
  methods: {
    handleClose: function handleClose() {
      this.$emit('close');
    },

    showInput: function showInput() {var _this = this;
      this.inputVisible = true;
      this.$nextTick(function () {
        _this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm: function handleInputConfirm() {
      this.inputVisible = false;
      if (this.form) {
        this.$emit('input', this.form);
        if (!this.value) {
          this.$emit('add', this.form);
          this.form = '';
        }
      } else {
        this.form = this.value;
      }
    } } };