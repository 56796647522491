import "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/core-js/modules/es.array.iterator.js";import "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/core-js/modules/es.promise.js";import "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/core-js/modules/es.object.assign.js";import "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/core-js/modules/es.promise.finally.js"; /*
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           * @Description:
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           * @Version: 1.0
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           * @Autor: silencc
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           * @Date: 2020-10-13 09:13:02
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           * @LastEditors: Please set LastEditors
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           * @LastEditTime: 2021-09-16 10:12:09
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           */
import Vue from 'vue';
import router from '@/router';
import store from '@/store';

/* 全局扩展配置 */
import plugins from '@/plugins/install';

import element from '@/utils/element';

// /*载入全局样式*/
import '@/styles/index.scss';
// /*载入icons(背景图片)*/
// import '@/utils/icons/icons.scss';
// /*svg*/
import '@/icons';
// import VueScroller from 'vue-scroller'
// Vue.use(VueScroller)
Vue.use(element); // element
Vue.use(plugins); // plugins
// /* iconfont */
// import '@/assets/iconfont/iconfont.css'

var isDebugMode = process.env.NODE_ENV !== 'production';

Vue.config.debug = isDebugMode;
Vue.config.devtools = isDebugMode;
Vue.config.productionTip = isDebugMode;

import App from './App.vue';

window.$vm = new Vue({
  router: router,
  store: store,
  render: function render(h) {return h(App);} }).
$mount('#app');