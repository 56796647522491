var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-image-wrapper" },
    [
      _vm._l(_vm.list, function(item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "c-image",
            on: {
              click: function($event) {
                return _vm.review(item)
              }
            }
          },
          [
            _c(
              "img",
              _vm._b(
                {
                  class: { imgradius: _vm.radius },
                  attrs: { src: _vm.ossUrl + item }
                },
                "img",
                _vm.$attrs,
                false
              )
            )
          ]
        )
      }),
      _vm.visible
        ? _c(
            "el-dialog",
            {
              staticClass: "img-dialog",
              attrs: {
                "show-close": false,
                visible: _vm.visible,
                "append-to-body": "",
                width: "600px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.visible = $event
                }
              }
            },
            [
              _c("img", {
                class: { imgradius: _vm.radius },
                attrs: { src: _vm.ossUrl + _vm.showSrc, width: "100%" }
              })
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }