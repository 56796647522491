//
//
//
//
//
//

import vModelMixins from '../../mixins/vModelMixins';
export default {
  name: 'c-radio',
  mixins: [vModelMixins],
  props: {
    options: {
      type: Array,
      "default": function _default() {return [];} } } };