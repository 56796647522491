/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

var state = {
  phones: '',
  customer: null };


var mutations = {
  SET_PHONES: function SET_PHONES(state, data) {
    state.phones = data;
  },
  SET_CUSTOMER: function SET_CUSTOMER(state, data) {
    state.customer = data;
  } };


var actions = {
  setPhones: function setPhones(_ref, data) {var commit = _ref.commit,state = _ref.state;
    commit('SET_PHONES', data);
  },
  setCustomer: function setCustomer(_ref2, data) {var commit = _ref2.commit,state = _ref2.state;
    commit('SET_CUSTOMER', data);
  } };

var getters = {
  phones: function phones(state) {return state.phones;},
  customer: function customer(state) {return state.customer;} };

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters };