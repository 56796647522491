/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-10-13 09:13:02
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-15 03:00:34
 */
module.exports = {
  /**
                    * main title
                    */

  title: '贝纳Lis下单系统',
  /**
                       * @type {boolean} true | false
                       * @description Whether show the logo in sidebar
                       */
  sidebarLogo: true,
  /**
                      *   baidu map key
                      */
  bak: '5tuMV4OjenLLHjb6M6Wnao1kZD24jTUe',
  /**
                                            *   GaoDe map key
                                            */
  gak: '3dca8f8afa23412e164dbfe2ab96d641',
  /**
                                            *   qq map key
                                            */
  tak: 'PHEBZ-HA53V-CP6PA-UIEUX-BFIMV-74FGY',
  /**
                                               * 开启TagsView
                                               */
  needTagsView: false,
  /*
                        是否开启 路由，token 等权限验证   routerHook|store/permission
                        */
  checkRole: true,
  /*
                    是否开启锁屏页面  routerHook
                    */
  lockPage: false,
  /*
                      首页路由
                    */
  homePage: '/dashboard/index' };