var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "copyright" }, [
      _c("p", [_vm._v("©2021 医检助手科技股份有限公司")]),
      _c("p", [_vm._v("网站备案许可证号")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }