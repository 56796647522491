import "core-js/modules/es.function.name";import "core-js/modules/es.object.freeze"; //
//
//
//
//
//
//
//
//

import { status as statusOptions } from '@/utils/options';
export default {
  name: 'c-switch',
  props: {
    value: {},
    name: {
      type: String },

    disabled: {
      type: [Boolean, Function],
      "default": false },

    intercept: {
      type: Boolean,
      "default": true },

    row: {
      type: Object,
      "default": function _default() {return {};} } },


  data: function data() {
    return {
      statusOptions: Object.freeze(statusOptions) };

  },
  methods: {
    change: function change(val) {
      if (this.intercept) {
        this.$emit('change', val, this.row, this.name);
      } else {
        this.$emit('input', val);
      }
    } } };