var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.drag
    ? _c(
        "el-upload",
        _vm._b(
          {
            ref: "uploader",
            staticClass: "upload-file",
            attrs: {
              accept: _vm.accept,
              action: "",
              "http-request": _vm.upload,
              "before-remove": _vm.beforeRemove,
              "before-upload": _vm.beforeUpload,
              "file-list": _vm.fileList,
              headers: _vm.headers,
              limit: _vm.limit,
              multiple: false,
              "on-error": _vm.handleError,
              "on-exceed": _vm.handleExceed,
              "on-preview": _vm.handlePreview,
              "on-remove": _vm.handleRemove,
              "on-success": _vm.handleSuccess,
              drag: ""
            }
          },
          "el-upload",
          _vm.$attrs,
          false
        ),
        [
          _c("i", { staticClass: "el-icon-upload" }),
          _c("div", { staticClass: "el-upload__text" }, [
            _vm._v(" 将文件拖到此处，或 "),
            _c("em", [_vm._v("点击上传")])
          ]),
          _vm.tips
            ? _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip"
                },
                [_vm._v(_vm._s(_vm.desc))]
              )
            : _vm._e()
        ]
      )
    : _c(
        "el-upload",
        _vm._b(
          {
            ref: "uploader",
            staticClass: "upload-file",
            attrs: {
              accept: _vm.accept,
              action: "",
              "http-request": _vm.upload,
              "before-remove": _vm.beforeRemove,
              "before-upload": _vm.beforeUpload,
              "file-list": _vm.fileList,
              headers: _vm.headers,
              limit: _vm.limit,
              multiple: _vm.limit > 1,
              "on-error": _vm.handleError,
              "on-exceed": _vm.handleExceed,
              "on-preview": _vm.handlePreview,
              "on-remove": _vm.handleRemove,
              "on-success": _vm.handleSuccess
            }
          },
          "el-upload",
          _vm.$attrs,
          false
        ),
        [
          _c(
            "el-button",
            _vm._b(
              {
                attrs: {
                  size: _vm.btnProps.size || "small",
                  type: _vm.btnProps.type || "primary"
                }
              },
              "el-button",
              _vm.btnProps,
              false
            ),
            [_vm._v(_vm._s(_vm.btnText))]
          ),
          _vm.tips
            ? _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip"
                },
                [_vm._v(_vm._s(_vm.desc))]
              )
            : _vm._e()
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }