import "core-js/modules/es.array.index-of";import "core-js/modules/es.array.join";import "core-js/modules/es.date.to-string";import "core-js/modules/es.object.to-string";import "core-js/modules/es.regexp.constructor";import "core-js/modules/es.regexp.exec";import "core-js/modules/es.regexp.to-string";import "core-js/modules/es.string.match";import "core-js/modules/es.string.replace";import "core-js/modules/es.string.search";import "core-js/modules/es.string.split";
/**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       * encodeToURIString 把一个对象类型转化为问号参数类型的字符串
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       * @param data 要解析的对象
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       * @param isUIR 是否转化为URIComponent，默认转化，只有当值为false时，不转化
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       * @returns '' 问号参数类型的字符串
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       */
export var encodeToURIString = function encodeToURIString(data) {var isUIR = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var ary = [];
  if (Object.prototype.toString.call(data) === '[object Object]') {
    for (var key in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty(key)) {
        if (isUIR) {
          try {
            key = decodeURIComponent(key);
            data[key] = decodeURIComponent(data[key]);
            ary.push(key + '=' + encodeURIComponent(data[key]));
          } catch (ex) {
            ary.push(key + '=' + data[key]);
          }
        } else {
          ary.push(key + '=' + data[key]);
        }
      }
    }
  }

  return ary.join('&');
};

/**
    * @name  {[string]} url
    * @return {[string]} 获取网址参数
    */
export function getUrlParams(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  var r = decodeURI(window.location.search).
  substr(1).
  match(reg);
  if (r != null) return r[2];
  return null;
}

/**
   * @param {string} url
   * @returns {Object}
   */
export function param2Obj(url) {
  var search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
  '{"' +
  decodeURIComponent(search).
  replace(/"/g, '\\"').
  replace(/&/g, '","').
  replace(/=/g, '":"').
  replace(/\+/g, ' ') +
  '"}');

}

/**
   * addStringToUrl 将对象转化为问号参数形式添加在url地址的后面，会进行URI编码处理
   * @param url 原url地址
   * @param param 参数对象
   * @returns '' 返回拼接好的新url地址
   */
export function addParams(url, param) {
  if (!param) {
    return url;
  }
  var hasSearch = /\?/.test(url);
  var data = encodeToURIString(param);
  return url + (hasSearch ? '&' : '?') + data;
}

/* 删除url指定参数，返回url */
export function delParamsUrl(url, name) {
  var baseUrl = url.split('?')[0] + '?';
  var query = url.split('?')[1];
  if (query.indexOf(name) > -1) {
    var obj = {};
    var arr = query.split('&');
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].split('=');
      obj[arr[i][0]] = arr[i][1];
    }
    delete obj[name];
    return baseUrl +
    JSON.stringify(obj).
    replace(/[\"\{\}]/g, '').
    replace(/\:/g, '=').
    replace(/\,/g, '&');
  }
  return url;
}