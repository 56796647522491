import "core-js/modules/es.number.constructor"; /*
                                                 * @Description:
                                                 * @Version: 1.0
                                                 * @Autor: silencc
                                                 * @Date: 2020-10-13 09:13:03
                                                 * @LastEditors: silencc
                                                 * @LastEditTime: 2020-10-17 11:51:58
                                                 */
// v-model mixin
export default {
  props: {
    value: {
      type: [String, Number, Object, Array, Boolean],
      "default": function _default() {return undefined;} } },


  data: function data() {
    return {
      form: this.value };

  },
  watch: {
    value: function value(newVal) {
      this.form = newVal;
    },
    form: function form(val) {
      this.$emit('input', val);
    } },

  methods: {
    validate: function validate() {var _this$$refs$form;
      return (_this$$refs$form = this.$refs.form).validate.apply(_this$$refs$form, arguments);
    },
    clearValidate: function clearValidate() {var _this$$refs$form2;
      return (_this$$refs$form2 = this.$refs.form).clearValidate.apply(_this$$refs$form2, arguments);
    } } };