var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.group
    ? _c(
        "el-select",
        _vm._b(
          {
            on: { change: _vm.handleChange },
            model: {
              value: _vm.form,
              callback: function($$v) {
                _vm.form = $$v
              },
              expression: "form"
            }
          },
          "el-select",
          _vm.$attrs,
          false
        ),
        _vm._l(_vm.options, function(group) {
          return _c(
            "el-option-group",
            {
              key: group.value,
              attrs: { label: group.label, value: group.value }
            },
            _vm._l(group.options, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: {
                  disabled: _vm.setDisabled(item),
                  label: item.label,
                  value: item.value
                }
              })
            }),
            1
          )
        }),
        1
      )
    : _vm.cascader
    ? _c(
        "el-cascader",
        _vm._b(
          {
            attrs: {
              options: _vm.options,
              "show-all-levels": _vm.showAllLevels,
              props: _vm.strictly
            },
            on: { change: _vm.handleChange },
            model: {
              value: _vm.form,
              callback: function($$v) {
                _vm.form = $$v
              },
              expression: "form"
            }
          },
          "el-cascader",
          _vm.$attrs,
          false
        )
      )
    : _c(
        "el-select",
        _vm._b(
          {
            attrs: { disabled: _vm.readOnly },
            on: { change: _vm.handleChange },
            model: {
              value: _vm.form,
              callback: function($$v) {
                _vm.form = $$v
              },
              expression: "form"
            }
          },
          "el-select",
          _vm.$attrs,
          false
        ),
        _vm._l(_vm.options, function(item, index) {
          return _c("el-option", {
            key: index,
            attrs: {
              disabled: _vm.setDisabled(item),
              label: item.label,
              value: item.value
            }
          })
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }