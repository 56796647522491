import "core-js/modules/es.number.constructor";import "core-js/modules/es.regexp.constructor";import "core-js/modules/es.regexp.exec";import "core-js/modules/es.regexp.to-string";import "core-js/modules/es.string.replace"; //
//
//
//

var regs = {
  "int": /[^\d]/gi };


export default {
  name: 'c-input',
  props: {
    value: {
      type: String || Number,
      "default": '' },

    regType: {
      type: String,
      "default": 'int' },

    reg: {
      type: RegExp } },


  methods: {
    onInput: function onInput(v) {
      this.$emit('input', this.formater(v));
    },
    formater: function formater(v) {
      var reg;

      if (this.regType) {
        reg = regs[this.regType];
      }
      if (this.reg) {
        // eslint-disable-next-line prefer-destructuring
        reg = this.reg;
      }
      return reg ? v.replace(reg, '') : v;
    } } };