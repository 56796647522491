import "core-js/modules/es.date.now";import "core-js/modules/es.date.to-string";import "core-js/modules/es.object.to-string";import _slicedToArray from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/slicedToArray"; //
//
//
//
//
//
//
//
//
//
//
//
//
//

import { saveAs } from 'file-saver'; // https://github.com/eligrey/FileSaver.js
import { parseTime } from '../../lib/modules/date';

export default {
  components: {},
  props: {
    url: {
      type: String,
      "default": undefined },

    fileType: {
      type: String,
      "default": 'xlsx' },

    fileName: {
      type: String },

    type: {
      type: String,
      "default": 'POST' },

    params: {
      "default": function _default() {return {};} },

    data: {
      "default": function _default() {return {};} } },


  methods: {
    // download({url, params, data, fileName, fileType}) {
    //  async handleExports() {
    //   let [res, data] = await this.$Api.getblob('lectureProcess/export', {
    //     data: this.query
    //   },'POST')
    //   if (!data) {
    //     return
    //   }
    //   let url = window.URL.createObjectURL(new Blob([data], { type: 'application/octer-stream' }))
    //   let link = document.createElement('a')
    //   link.style.display = 'none'
    //   link.href = url
    //   link.setAttribute('download', '讲座学分审批导出.xls')
    //   document.body.appendChild(link)
    //   link.click()

    // },
    download: function download(_ref) {var _this = this;var url = _ref.url,params = _ref.params,data = _ref.data,fileName = _ref.fileName,fileType = _ref.fileType;
      var _url = url || this.url;
      var _params = params || this.params;
      var _data = data || this.data;
      var _fileName = fileName || this.fileName;
      var _fileType = fileType || this.fileType;
      var _type = this.type;

      if (_url) {
        return new Promise(function (resolve) {
          _this.$Api.
          getblob(_url, { params: _params, data: _data }, _type).
          then(function (_ref2) {var _ref3 = _slicedToArray(_ref2, 2),err = _ref3[0],res = _ref3[1];
            if (!err && res) {
              var file = new File(
              [res],
              (_fileName || "\u6587\u4EF6".concat(Date.now())) +
              parseTime(0, '{y}-{m}-{d}') + ".".concat(
              _fileType),
              {
                type: 'text/plain;charset=utf-8' });



              saveAs(file);
            }
            resolve([err, res]);
          });
        });
      }
    } } };