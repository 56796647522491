var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.component.name === "text" ||
      ((_vm.readOnlyFn
        ? _vm.readOnlyFn(_vm.propPrefix, _vm.innerValue)
        : _vm.readOnly) &&
        !_vm.component.always)
        ? _c(
            "div",
            _vm._g(
              _vm._b(
                {
                  staticStyle: { "white-space": "pre-wrap" },
                  style: _vm.component.textStyle ? _vm.component.textStyle : {},
                  domProps: { innerHTML: _vm._s(_vm.innerValue) },
                  on: {
                    click: function($event) {
                      return _vm.events("click")
                    }
                  }
                },
                "div",
                _vm.component.props,
                false
              ),
              _vm.component.events
            )
          )
        : _vm.component.name === "none"
        ? _c("div")
        : _vm.component.name === "el-button"
        ? _c(
            "el-button",
            _vm._b(
              {
                attrs: {
                  disabled:
                    typeof _vm.component.disabled === "function"
                      ? _vm.component.disabled(_vm.propPrefix)
                      : _vm.component.disabled || false
                },
                on: {
                  click: function($event) {
                    return _vm.events("click")
                  }
                }
              },
              "el-button",
              _vm.component.props,
              false
            ),
            [
              _vm._v(
                " " +
                  _vm._s(_vm.component.props && _vm.component.props.text) +
                  " "
              )
            ]
          )
        : _c(
            _vm.component.name || "el-input",
            _vm._g(
              _vm._b(
                {
                  tag: "component",
                  attrs: {
                    readOnly: _vm.component.readOnlyFn
                      ? _vm.component.readOnlyFn(_vm.propPrefix, _vm.innerValue)
                      : null,
                    clearable: _vm.component.readOnlyFn
                      ? !_vm.component.readOnlyFn(
                          _vm.propPrefix,
                          _vm.innerValue
                        )
                      : null,
                    propPrefix: _vm.propPrefix,
                    value: _vm.innerValue
                  },
                  on: { input: _vm.onInput }
                },
                "component",
                _vm.component.props,
                false
              ),
              _vm.component.events
            )
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }