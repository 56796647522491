import "core-js/modules/es.number.constructor"; //
//
//
//
//
//
//
//
//
//
//
//

import { parseTime } from '../../lib/modules/date';

export default {
  name: 'my-time',
  props: {
    time: {
      type: [String, Number],
      "default": '' },

    empty: {
      type: String,
      "default": '---' } },


  computed: {
    timeStr: function timeStr() {
      return parseTime(this.time) || this.empty;
    } } };