var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-row",
    _vm._b({}, "c-row", _vm.$attrs, false),
    _vm._l(_vm.options, function(item, idx) {
      return _c(
        "c-col",
        { key: idx, attrs: { span: item.span } },
        [
          _c(
            "c-col-item",
            { attrs: { label: item.label } },
            [
              _vm._t(item.kname, [
                item.component && item.component.name
                  ? _c(
                      item.component.name,
                      _vm._g(
                        _vm._b(
                          { tag: "component" },
                          "component",
                          item.component.props,
                          false
                        ),
                        item.component.events
                      )
                    )
                  : [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.filterFn
                              ? item.filterFn(_vm.data[item.kname], _vm.data)
                              : [undefined, null, ""].includes(
                                  _vm.data[item.kname]
                                )
                              ? _vm.placeholder
                              : _vm.data[item.kname]
                          ) +
                          " "
                      )
                    ]
              ])
            ],
            2
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }