import "core-js/modules/web.timers"; /*
                                       创建一个计时器， 2 秒后执行函数
                                       当用户按下按钮时触发 mousedown 事件，启动计时器；用户松开按钮时调用 mouseout 事件。
                                       如果 mouseup 事件 2 秒内被触发，就清除计时器，当作一个普通的点击事件
                                       如果计时器没有在 2 秒内清除，则判定为一次长按，可以执行关联的函数。
                                       在移动端要考虑 touchstart，touchend 事件
                                     
                                       作者：lzg9527
                                       链接：https://juejin.cn/post/6906028995133833230
                                       来源：掘金
                                       著作权归作者所有。商业转载请联系作者获得授权，非商业转载请注明出处。
                                     
                                       <template>
                                         <button v-longpress="longpress">长按</button>
                                       </template>
                                     
                                       <script>
                                       export default {
                                         methods: {
                                           longpress () {
                                             alert('长按指令生效')
                                           }
                                         }
                                       }
                                       </script>
                                     */

var longpress = {
  bind: function bind(el, binding, vNode) {
    if (typeof binding.value !== 'function') {
      // throw 'callback must be a function'
      return;
    }
    // 运行函数
    var handler = function handler(e) {
      binding.value(e);
    };
    // 定义变量
    var pressTimer = null;
    // 创建计时器（ 2秒后执行函数 ）
    var start = function start(e) {
      if (e.type === 'click' && e.button !== 0) {
        return;
      }
      if (pressTimer === null) {
        pressTimer = setTimeout(function () {
          handler();
        }, 2000);
      }
    };
    // 取消计时器
    var cancel = function cancel(e) {
      if (pressTimer !== null) {
        clearTimeout(pressTimer);
        pressTimer = null;
      }
    };

    // 添加事件监听器

    el.addEventListener('mousedown', start);
    el.addEventListener('touchstart', start);
    // 取消计时器
    el.addEventListener('click', cancel);
    el.addEventListener('mouseout', cancel);
    el.addEventListener('touchend', cancel);
    el.addEventListener('touchcancel', cancel);
  },
  // 当传进来的值更新的时候触发
  componentUpdated: function componentUpdated(el, _ref) {var value = _ref.value;
    el.$value = value;
  },
  // 指令与元素解绑的时候，移除事件绑定
  unbind: function unbind(el) {
    el.removeEventListener('click', el.handler);
  } };


export default longpress;