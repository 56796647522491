import "core-js/modules/es.number.constructor"; //
//
//
//
//
//
//
//

export default {
  name: 'c-col',
  componentName: 'c-col',
  props: {
    span: {
      type: Number },

    tag: {
      type: String,
      "default": 'div' },

    labelAlign: {
      type: String },

    labelBcolor: {
      type: String } },


  data: function data() {
    return {
      labelWidth: undefined,
      padding: undefined };

  },

  computed: {
    gutter: function gutter() {
      var parent = this.$parent;

      while (parent && parent.$options.componentName !== 'c-row') {
        parent = parent.$parent;
      }
      return parent ? parent.gutter : 0;
    },
    borderStyle: function borderStyle() {
      var parent = this.$parent;

      while (parent && parent.$options.componentName !== 'c-row') {
        parent = parent.$parent;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.labelWidth = parent.labelWidth;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.padding = parent.padding;
      return parent && parent.border ? parent.borderStyle : '';
    },
    width: function width() {
      var span = this.span || 24;

      return span / 24 * 100 + '%';
    } },

  render: function render(h) {
    var style = {
      flexShrink: 0,
      width: this.width };


    if (this.gutter) {
      style.paddingLeft = this.gutter / 2 + 'px';
      style.paddingRight = style.paddingLeft;
    }
    if (this.borderStyle) {
      style.borderRight = this.borderStyle;
      style.borderBottom = this.borderStyle;
    }
    return h(
    this.tag,
    {
      style: style },

    this.$slots["default"]);

  } };