import "core-js/modules/es.array.concat";import "core-js/modules/es.array.join";import "core-js/modules/es.array.reduce";import "core-js/modules/es.array.slice";import "core-js/modules/es.date.to-string";import "core-js/modules/es.number.constructor";import "core-js/modules/es.object.to-string";import "core-js/modules/es.parse-int";import "core-js/modules/es.regexp.exec";import "core-js/modules/es.regexp.to-string";import "core-js/modules/es.string.replace";import "core-js/modules/es.string.split"; /* eslint-disable no-bitwise */
/* eslint-disable no-var */
/* 随机数范围 */
export function random(min, max) {
  if (arguments.length === 2) {
    return Math.floor(min + Math.random() * (max + 1 - min));
  }
  return undefined;
}


/*
    使用方法：
    Money（value,num）的第一位value是必传的，第二位num是选填的 不填是保留两位小数
    console.log(Money('123456789',2))  -->123,456,789.00
  */
export function parseMoney(val) {
  // eslint-disable-next-line no-use-before-define
  return numberFormat(val, 2);
}

/*
   * 参数说明：
   * number：要格式化的数字
   * decimals：保留几位小数
   * deCpoint：小数点符号
   * thousandSep：千分位符号
   * */
export function numberFormat(number, decimals, deCpoint, thousandSep) {
  number = (number + '').replace(/[^0-9+-Ee.]/g, '');
  var n = !isFinite(+number) ? 0 : +number;
  var prec = !isFinite(+decimals) ? 2 : Math.abs(decimals);
  var sep = typeof thousandSep === 'undefined' ? ',' : thousandSep;
  var dec = typeof deCpoint === 'undefined' ? '.' : deCpoint;
  var s = '';
  // eslint-disable-next-line no-shadow
  var toFixedFix = function toFixedFix(n, prec) {
    // eslint-disable-next-line no-restricted-properties
    var k = Math.pow(10, prec);
    return '' + Math.ceil(n * k) / k;
  };

  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  var re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2');
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}


// 1. `randomIntegerInRange`：生成指定范围的随机整数
export var randomIntegerInRange = function randomIntegerInRange(min, max) {return Math.floor(Math.random() * (max - min + 1)) + min;};
// randomIntegerInRange(0, 5); // 3

// 2. `randomNumberInRange`：生成指定范围的随机小数
export var randomNumberInRange = function randomNumberInRange(min, max) {return Math.random() * (max - min) + min;};
// randomNumberInRange(2, 10); // 6.0211363285087005

// 3. `round`：四舍五入到指定位数
export var round = function round(n) {var decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;return Number("".concat(Math.round("".concat(n, "e").concat(decimals)), "e-").concat(decimals));};
// round(1.005, 2); // 1.01

// 4. `sum`：计算数组或多个数字的总和
export var sum = function sum() {for (var _len = arguments.length, arr = new Array(_len), _key = 0; _key < _len; _key++) {arr[_key] = arguments[_key];}return [].concat(arr).reduce(function (acc, val) {return acc + val;}, 0);};
// sum(1, 2, 3, 4); // 10
// sum(...[1, 2, 3, 4]); // 10

// 5. `toCurrency`：简单的货币单位转换
export var toCurrency = function toCurrency(n) {var curr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'CNY';var LanguageFormat = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;return Intl.NumberFormat(LanguageFormat, { style: 'currency', currency: curr }).format(n);};
// toCurrency(123456.789, 'EUR'); // €123,456.79
// toCurrency(123456.789, 'USD', 'en-us'); // $123,456.79
// toCurrency(123456.789, 'USD', 'fa'); // ۱۲۳٬۴۵۶٫۷۹
// toCurrency(322342436423.2435, 'JPY'); // ¥322,342,436,423

// `Random Hexadecimal Color Code`：随机十六进制颜色
export var randomHexColorCode = function randomHexColorCode() {
  var n = (Math.random() * 0xfffff * 1000000).toString(16);
  return '#' + n.slice(0, 6);
};
// randomHexColorCode(); // "#e34155"

/**
 * @description: 以编程方式使十六进制颜色（或rgb和混合颜色）变亮或变暗
 * @param col {String|Hex16} 颜色值： '3F6D2A' '#3F6D2A' 字符串或者 16进制数字 0x113211
 * @param amt {Number} 颜色调整值  正为浅 负为深
 * @return {String}颜色值：
 * @author: silencc
 *  use:
    var myColor = "3F6D2A";
    myColor = LightenDarkenColor(myColor,10);
    thePlaceTheColorIsUsed = ("#" + myColor);
 */
export var LightenDarkenColor = function LightenDarkenColor(col, amt) {
  var usePound = false;
  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }
  var num = parseInt(col, 16);
  var r = (num >> 16) + amt;
  if (r > 255) r = 255;else
  if (r < 0) r = 0;

  var b = (num >> 8 & 0x00FF) + amt;

  if (b > 255) b = 255;else
  if (b < 0) b = 0;

  var g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;else
  if (g < 0) g = 0;
  return (usePound ? '#' : '') + (g | b << 8 | r << 16).toString(16);
};