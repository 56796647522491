/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-10-13 09:13:03
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-11-30 11:50:21
 */
/* 展馆管理 */
import { load } from '../routeloader';
import EmptyRoute from '@/layout/EmptyRoute';
export default {
  path: '/project',
  component: load('layout/index'),
  redirect: 'noRedirect',
  name: 'layout',
  // 不管子菜单有几个都显示
  alwaysShow: true,
  meta: {
    title: '项目管理',
    icon: 'iconfont icon-gongzuotai'
    // roles: [126]
  },
  children: [
  {
    path: 'extensionList',
    component: load('views/project/extensionList/index'),
    name: 'project-extensionList',
    meta: {
      title: '项目列表-推广' } },


  {
    path: 'article',
    component: load('views/project/article/index'),
    name: 'videos-article',
    meta: {
      title: '文章列表'
      // img: require('@/assets/icons/1.png'),
      // affix: true
      // matchedKey: 'layout'
      // noCache: true
      // roles: [131]
    } }] };