// import { parseTime } from '@/plugins/lib/modules/date'
import { status, getType } from '@/utils/options';

export var searchOption = [
{
  kname: 'title',
  label: '货箱名称：',
  component: {
    name: 'el-input',
    props: {
      clearable: true,
      placeholder: '请输入' } } },



{
  kname: 'pos',
  label: '状态：',
  component: {
    name: 'selector' } }];



export var columns = function columns(ctx) {return [
  // {
  //   type: 'selection'
  // },
  {
    label: '序号',
    type: 'index',
    width: 60 },

  {
    label: '货箱名称',
    kname: 'id' },

  {
    label: '归属部门',
    kname: 'id' },

  {
    label: '位置',
    kname: 'id' },

  {
    label: '货品来源',
    kname: 'id' },

  {
    label: '预存时间（天）',
    kname: 'id' },

  {
    label: '货箱货品个数',
    kname: 'id' },

  {
    label: '创建时间',
    kname: 'id' },

  {
    label: '状态',
    kname: 'id' },

  {
    label: '操作',
    kname: 'btns',
    width: 200 }];};