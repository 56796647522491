import "core-js/modules/es.array.splice";import "core-js/modules/es.regexp.exec";import "core-js/modules/es.string.split"; /**
                                                                                                                            * ruleTypes: 规则类型
                                                                                                                            * genRules： rule 生成函数
                                                                                                                            * ctx： Vue实例
                                                                                                                            */
import { genModels } from '@/plugins/widget/c-form/rules';

// 定义模型
export var models2 = genModels(function (genRules, ctx) {return [
  {
    span: 6,
    kname: 'aa',
    itemProps: {
      label: '货品：',
      labelWidth: '70px',
      rules: [
      genRules('require')] } },



  {
    span: 6,
    kname: 'aa',
    itemProps: {
      label: '规格：',
      labelWidth: '70px',
      rules: [
      genRules('require')] } },



  {
    span: 5,
    kname: 'aa',
    itemProps: {
      label: '数量：',
      labelWidth: '70px',
      rules: [
      genRules('require')] } },



  {
    span: 5,
    kname: 'aa',
    itemProps: {
      label: '单位：',
      labelWidth: '70px',
      rules: [
      genRules('require')] } },



  {
    span: 2,
    component: {
      name: 'el-button',
      props: {
        type: 'danger',
        text: '删除' },

      events: {
        click: function click(e) {
          var arr = e.split('.');

          console.log(arr);
          ctx.form[arr[0]].splice(arr[1], 1);
        } } },


    itemProps: {
      labelWidth: '20px',
      // label: '价格：',
      rules: [
      genRules('require')
      // genRules('money')
      ] } }];});




export var models = genModels(function (genRules, ctx) {return [
  {
    span: 12,
    kname: 'name',
    itemProps: {
      label: '所属部门：',
      rules: [
      genRules('require')] },


    component: {
      name: 'selector',
      props: {
        type: 'xxx' } } },



  {
    span: 12,
    kname: 'name',
    itemProps: {
      label: '货品来源：',
      rules: [
      genRules('require')] } },



  {
    span: 12,
    kname: 'name',
    itemProps: {
      label: '库位编码：',
      rules: [
      genRules('require')] },


    component: {
      name: 'selector',
      props: {
        type: 'xxx' } } },



  {
    span: 12,
    kname: 'name',
    itemProps: {
      label: '预存天数：',
      rules: [
      genRules('require')] } },



  {
    span: 12,
    kname: 'name',
    itemProps: {
      label: '操作人员：',
      rules: [
      genRules('require'),
      genRules('int')] } }];});






// form 默认值
export var formData = function formData() {return {};};