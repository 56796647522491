var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      _c(
        "c-box",
        {
          attrs: { slot: "header", flex: "row", justify: "space-between" },
          slot: "header"
        },
        [
          _c(
            "c-search",
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        icon: "el-icon-s-check"
                      },
                      on: { click: _vm.handleApprove }
                    },
                    [_vm._v(" 审批 ")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "warning",
                        icon: "el-icon-more"
                      }
                    },
                    [_vm._v(" 审批中 ")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        icon: "el-icon-paperclip"
                      }
                    },
                    [_vm._v(" 附件 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("c-grid-table", {
        attrs: {
          options: _vm.detailOption,
          data: _vm.info,
          border: true,
          labelWidth: "160px",
          color: "#333"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }